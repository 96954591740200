import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';
import { useVideoConfig } from 'remotion';

import { type RenderDescription, secondsToFrames as stf } from '@cofenster/render-description';

import type { Template } from '../../template/Template';

import { CofensterVideoContext } from './CofensterVideoContext';

export type CofensterVideoProviderProps = PropsWithChildren<{
  template: Template;
  renderDescription: RenderDescription;
  isPreview: boolean;
  onError?: VoidFunction;
}>;

export const CofensterVideoProvider: FC<CofensterVideoProviderProps> = ({
  children,
  template,
  renderDescription,
  isPreview,
  onError,
}) => {
  const { fps, durationInFrames } = useVideoConfig();
  const secondsToFrames = useCallback((seconds: number) => stf(seconds, fps), [fps]);
  const context = useMemo(
    () => ({
      template,
      renderDescription,
      isPreview,
      fps,
      durationInFrames,
      secondsToFrames,
      onError,
    }),
    [template, renderDescription, isPreview, fps, durationInFrames, secondsToFrames, onError]
  );

  return <CofensterVideoContext.Provider value={context}>{children}</CofensterVideoContext.Provider>;
};
