import { type FC, Fragment } from 'react';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { SceneVideoElement } from '../Scenes/Scene/SceneAssets/SceneVideo/SceneVideoElement';

export const Outro: FC = () => {
  const { template, renderDescription } = useCofensterVideo();
  const { outro } = renderDescription;

  if (!outro) return null;

  const Container = template.outro?.container || FullSizeFlexContainer;
  const Wrapper = template.outro?.wrapper || Fragment;

  return (
    <UnstylableContainer className={'outro'}>
      <Container>
        <Wrapper>
          <SceneVideoElement src={outro.srcUrl} background={renderDescription.background} muted={false} startFrom={0} />
        </Wrapper>
      </Container>
    </UnstylableContainer>
  );
};
