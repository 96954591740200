import { useMemo } from 'react';
import { Easing, interpolate } from 'remotion';

// duration in frames of the fade in/out
const FADE_DURATION = 30;

// minimal volume when ducked
const MIN_VOLUME = 0.08;

// value (between 0 and 1) above which it is considered to trigger ducking
const DUCKING_THRESHOLD = 0.3;

// number of frames (before & ahead) used to calculate the average
const AVERAGE_BEFORE = 10;
const AVERAGE_AHEAD = 15;

/**
 *
 * @param duckingFactors
 * @param volume between 0 and 1
 * @param duration in frames
 * @param offset in frames
 */
export const useDuckingVolume = (duckingFactors: number[] | null, volume: number, duration: number, offset = 0) => {
  return useMemo(() => {
    const fade = (frame: number) =>
      interpolate(frame, [0, FADE_DURATION, duration - FADE_DURATION, duration - 1], [0, 1, 1, 0], {
        easing: Easing.out(Easing.ease),
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
      });

    if (!duckingFactors) return (frame: number) => volume * fade(Math.floor(frame));

    return (frame: number) => {
      frame = Math.floor(frame);
      const duckingFactor = averageFactor(duckingFactors, frame + offset);
      const duckedVolume = MIN_VOLUME + (1 - duckingFactor) * (volume - MIN_VOLUME);

      return duckedVolume * fade(frame);
    };
  }, [duckingFactors, volume, offset, duration]);
};

const averageFactor = (duckingFactors: number[] | null, frame: number) => {
  const values: number[] = [];
  for (let i = frame - AVERAGE_BEFORE; i < frame + AVERAGE_AHEAD; i++) {
    values.push(duckingFactors?.[i] ?? 0);
  }

  return values.map((value): number => (value > DUCKING_THRESHOLD ? 1 : 0)).reduce((a, b) => a + b, 0) / values.length;
};
