import BezierEasing from 'bezier-easing';
import { useCurrentFrame, useVideoConfig } from 'remotion';

import { secondsToFrames } from '@cofenster/render-description';
import { type CubicBezier, type EasingFunction, type EasingPresets, presets } from '../easing';

const DEFAULTS = {
  easing: 'linear' as EasingPresets | CubicBezier | EasingFunction,
  fromValue: 0,
  toValue: 1,
  animationDuration: 1,
  startTime: 0,
  endTime: 0,
  alternate: false,
};

type Defaults = typeof DEFAULTS;

export type UseRepeatedAnimateConfig = Partial<Defaults>;

export const useRepeatedAnimate = (config: UseRepeatedAnimateConfig) => {
  let frame = useCurrentFrame();
  const { durationInFrames, fps } = useVideoConfig();

  const settings = {
    ...DEFAULTS,
    ...config,
  };

  settings.animationDuration = secondsToFrames(settings.animationDuration, fps);
  settings.startTime = secondsToFrames(settings.startTime, fps);
  if (settings.endTime <= 0) {
    settings.endTime = durationInFrames + settings.endTime;
  }
  settings.endTime = secondsToFrames(settings.endTime, fps);

  if (frame < settings.startTime) {
    return settings.fromValue;
  }
  frame = Math.min(frame, settings.endTime);
  const elapsed = frame - settings.startTime;
  let percentage = (elapsed % settings.animationDuration) / settings.animationDuration;
  if (settings.alternate) {
    const repeatCount = Math.floor(elapsed / settings.animationDuration);
    if (repeatCount % 2) {
      percentage = 1 - percentage;
    }
  }
  const easingFunction =
    typeof settings.easing === 'string'
      ? presets[settings.easing]
      : typeof settings.easing === 'function'
        ? settings.easing
        : BezierEasing(...settings.easing);
  const easedPercentage = easingFunction(percentage);
  return settings.fromValue + (settings.toValue - settings.fromValue) * easedPercentage;
};
