import { Global } from '@emotion/react';
import type { FC } from 'react';

import { useCofensterVideo } from '../../../context/cofensterVideo';

export const Sizes: FC = () => {
  const { renderDescription } = useCofensterVideo();

  return (
    <Global
      styles={{
        '.cofenster-video': {
          '--video-width': `${renderDescription.format.width}px`,
          '--video-height': `${renderDescription.format.height}px`,
        },
      }}
    />
  );
};
