import { Global } from '@emotion/react';
import { type FC, Fragment } from 'react';

import {
  type SceneAsset,
  isSceneColor,
  isSceneImage,
  isSceneVideo,
  secondsToFrames,
} from '@cofenster/render-description';
import { isSceneAudio } from '@cofenster/render-description';

import { LAYER_INDEXES } from '@cofenster/constants';
import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { createColorVariables } from '../../../../../../helpers/createColorVariables';
import { SceneAudio } from '../SceneAudio';
import { SceneColor } from '../SceneColor';
import { SceneImage } from '../SceneImage';
import { SceneVideo } from '../SceneVideo';

export type SceneAssetsLayerProps = {
  sceneIndex: number;
  layerIndex: number;
  sceneAssets: SceneAsset[];
};

export const SceneAssetsLayer: FC<SceneAssetsLayerProps> = ({ sceneIndex, layerIndex, sceneAssets }) => {
  const { fps } = useCofensterVideo();

  return sceneAssets.map((sceneAsset, assetIndex) => {
    const from = secondsToFrames(sceneAsset.startTime ?? 0, fps);

    if (isSceneVideo(sceneAsset)) {
      return <SceneVideo key={sceneAsset.id} from={from} index={assetIndex} sceneVideo={sceneAsset} />;
    }

    if (isSceneImage(sceneAsset)) {
      return <SceneImage key={sceneAsset.id} from={from} index={assetIndex} sceneImage={sceneAsset} />;
    }

    if (isSceneColor(sceneAsset)) {
      return (
        <Fragment key={sceneAsset.id}>
          <SceneColor from={from} index={assetIndex} sceneColor={sceneAsset} />

          {
            // Render the global style only for a color sceneAsset on the main layer (layer 1)
            layerIndex === LAYER_INDEXES.main && (
              <Global
                styles={{
                  [`.scene.scene-${sceneIndex}`]: createColorVariables(sceneAsset.color, 'scene-color'),
                }}
              />
            )
          }
        </Fragment>
      );
    }

    if (isSceneAudio(sceneAsset)) {
      return <SceneAudio key={sceneAsset.id} sceneAudio={sceneAsset} />;
    }

    return null;
  });
};
