import { useCofensterVideo } from '../../context/cofensterVideo/useCofensterVideo';
import type { StyleDefinition, TextStyleDefinition } from './types';

export type TemplateDefinitionTextElement = {
  options?: TemplateDefinitionTextElementOptions;
  wrapper?: StyleDefinition;
  element?: StyleDefinition;
  background?: StyleDefinition;
  texts?: StyleDefinition & { before?: StyleDefinition; after?: StyleDefinition };
  text?: Record<
    string,
    TextStyleDefinition & {
      beforeContent?: StyleDefinition;
      before?: StyleDefinition;
      after?: StyleDefinition;
      afterContent?: StyleDefinition;
    }
  >;
};

export type TemplateDefinitionTextElementOptions = {
  hideLogo?: boolean;
};

export type TemplateDefinitionLogo = {
  element?: StyleDefinition;
  wrapper?: StyleDefinition;
};

export type TemplateDefinitionTransition = Record<string, StyleDefinition>;

export type TemplateDefinition = {
  textElements: Record<string, TemplateDefinitionTextElement>;
  logo?: TemplateDefinitionLogo;
  transition?: TemplateDefinitionTransition;
};

const NODE_ENV = process.env.NODE_ENV;

export const useTemplateDefinition = () => {
  const { renderDescription } = useCofensterVideo();

  if (NODE_ENV === 'development') {
    return templateDefinition;
  }

  return renderDescription.templateDefinition as TemplateDefinition;
};

const templateDefinition: TemplateDefinition = {
  transition: {
    element_1: {
      css: {
        width: '100%',
        height: '100%',
        backgroundColor: '#000033',
      },
      animations: {
        backgroundColor: {
          '0': { v: '#000033' },
          '-0': { v: '#330000' },
        },
      },
      svg: {
        element: {
          viewBox: '0 0 100 100',
          css: {
            width: '50%',
            height: '50%',
            backgroundColor: '#000000',
          },
          animations: {
            transform: {
              '0': { v: 'none' },
              '-0': { v: 'translate(100%, 100%)' },
            },
          },
        },
      },
    },
  },
  textElements: {
    TitleLarge: {
      options: {
        hideLogo: true,
      },
      texts: {
        after: {
          svg: {
            element: {
              viewBox: '0 0 100 100',
              css: {
                width: '100px',
                height: '100px',
                backgroundColor: '#000000',
              },
            },
          },
        },
        before: {
          css: {
            width: '200px',
            height: '200px',
            backgroundColor: 'blue',
            backgroundImage: 'var(--logo-url)',
            backgroundRepeat: 'repeat',
          },
        },
        css: {
          position: 'absolute',
          bottom: 16,
          left: 16,
          fontSize: 50,
        },
        animations: {
          opacity: {
            '0': { v: 0 },
            '1000': { v: 1 },
            '3000': { v: 1 },
            '4000': { v: 0 },
            '-0': { v: 0 },
          },
        },
      },
      text: {
        text1: {
          after: {
            css: {
              width: '100px',
              height: '100px',
              background: 'red',
            },
          },
          css: {
            background: 'white',
          },
          selectors: {
            'word:first': {
              css: {
                color: 'blue',
              },
            },
            'word:even': {
              css: {
                border: 'solid 1px orange',
              },
            },
            'word:last': {
              css: {
                color: 'blue',
              },
            },
            'word-in-line:first': {
              css: {
                textDecoration: 'underline',
              },
            },
            'word-in-line:last': {
              css: {
                textDecoration: 'overline',
              },
            },
            'line:first': {
              css: {
                color: 'red',
              },
            },
            'line:each': {
              css: {
                display: 'inline-block',
                padding: '10px',
                margin: '10px',
                color: 'white',
                background: 'black',
              },
            },
          },
        },
      },
    },
  },
  logo: {
    element: {
      css: {
        position: 'absolute',
      },
      svg: {
        element: {
          viewBox: '0 0 100 100',
          css: {
            width: '100px',
            height: '100px',
          },
        },
        path_0: {
          css: {
            d: 'path("M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0")',
            stroke: '#FFFF00',
            fill: 'transparent',
          },
        },
      },
    },
  },
};
