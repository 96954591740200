import { type FC, type PropsWithChildren, useMemo } from 'react';

import type { Scene } from '@cofenster/render-description';

import { SceneDescriptionContext } from './SceneDescriptionContext';

export type SceneDescriptionProviderProps = PropsWithChildren<{
  sceneDescription: Scene;
}>;

export const SceneDescriptionProvider: FC<SceneDescriptionProviderProps> = ({ children, sceneDescription }) => {
  const context = useMemo(() => ({ sceneDescription }), [sceneDescription]);

  return <SceneDescriptionContext.Provider value={context}>{children}</SceneDescriptionContext.Provider>;
};
