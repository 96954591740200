import { useMemo } from 'react';
import { useVideoConfig } from 'remotion';

import {
  type AllowedProperties,
  type AnimatedProperty,
  type AnimatedValue,
  type AnimationsDeclarations,
  type CSSVariables,
  type StyleProperties,
  type TextContentSelector,
  type TextContentSelectorA,
  type TextContentSelectorB,
  type Timeline,
  type WithTextContentSelector,
  asTimelines,
  isAllowedProperty,
  isAnimatedProperty,
  isCSSProperty,
  isCSSVariable,
  isEmptyValue,
  skipMetadata,
} from './types';
import { useUniqueIdentifier } from './useUniqueIdentifier';

const joinAnimationsDeclarations = (
  { animation: leftAnimation, ...leftKeyframes }: AnimationsDeclarations,
  { animation: rightAnimation, ...rightKeyframes }: AnimationsDeclarations
) => ({
  ...leftKeyframes,
  ...rightKeyframes,
  animation: [leftAnimation, rightAnimation].filter(Boolean).join(', '),
});

const toAnimationsDeclarations =
  (parentDuration: number, identifier: string, property: AnimatedProperty) =>
  (timeline: Timeline, index: number): AnimationsDeclarations => {
    const times = Object.keys(skipMetadata(timeline)).map((time) =>
      time.startsWith('-') ? parentDuration + Number.parseInt(time, 10) : Number.parseInt(time, 10)
    );
    const startTime = Math.min(...times);
    const endTime = Math.max(...times);
    const duration = endTime - startTime;
    const offset = startTime;
    const name = `animation-${identifier}-${property}-${index}`;

    const animation = `${duration}ms linear calc(${offset}ms + var(--animation-delay)) none paused ${name}`;
    const keyframes = {
      [`@keyframes ${name}`]: Object.fromEntries(
        Object.entries(skipMetadata(timeline)).map(([time, keyframe]) => {
          const parentTime = time.startsWith('-')
            ? parentDuration + Number.parseInt(time, 10)
            : Number.parseInt(time, 10);
          const localTime = parentTime - startTime;
          const percentage = `${(localTime / duration) * 100}%`;

          return [
            percentage,
            {
              [property]: keyframe.v,
              animationTimingFunction: keyframe.e ?? 'linear',
              ...(property === 'transform' && { animationComposition: 'add' }),
            },
          ] as const;
        })
      ),
    };

    return {
      animation,
      ...keyframes,
    };
  };

export const useStyleDeclaration = (styleProps?: WithTextContentSelector<StyleProperties>) => {
  const { durationInFrames, fps } = useVideoConfig();
  const durationInMs = (durationInFrames / fps) * 1000;
  const id = useUniqueIdentifier();

  return useMemo(() => {
    if (!styleProps) return {};

    const staticDeclarations = getStaticDeclarations(styleProps.css ?? {});
    const selectorDeclarations = getSelectorDeclarations(styleProps.selectors);
    const animationsDeclarations = getAnimationsDeclarations(styleProps, durationInMs, id);

    return {
      ...staticDeclarations,
      ...animationsDeclarations,
      ...selectorDeclarations,
    };
  }, [styleProps, durationInMs, id]);
};

type SpecificSelectors = Exclude<TextContentSelector, `line:${number}` | `word:${number}` | `word-in-line:${number}`>;

const selectorsPriorities: Record<SpecificSelectors, number> = {
  'line:each': 1,
  'line:odd': 2,
  'line:even': 3,
  'line:first': 4,
  'line:last': 5,
  'word:each': 100,
  'word:odd': 101,
  'word:even': 102,
  'word:first': 103,
  'word:last': 104,
  'word-in-line:each': 200,
  'word-in-line:odd': 201,
  'word-in-line:even': 202,
  'word-in-line:first': 203,
  'word-in-line:last': 204,
};

const getSelectorDeclarations = (
  selectors: Partial<Record<TextContentSelector, { css: AllowedProperties & CSSVariables }>> | undefined
) => {
  if (!selectors) return;

  const entries = Object.entries(selectors)
    .sort((a, b) => {
      const [aSelector] = a;
      const [bSelector] = b;

      let aPriority = selectorsPriorities[aSelector as SpecificSelectors];
      let bPriority = selectorsPriorities[bSelector as SpecificSelectors];

      if (!aPriority) {
        if (aSelector.startsWith('line:')) aPriority = 50;
        if (aSelector.startsWith('word:')) aPriority = 150;
        if (aSelector.startsWith('word-in-line:')) aPriority = 250;
      }

      if (!bPriority) {
        if (bSelector.startsWith('line:')) bPriority = 50;
        if (bSelector.startsWith('word:')) bPriority = 150;
        if (bSelector.startsWith('word-in-line:')) bPriority = 250;
      }

      return aPriority - bPriority;
    })
    .map(
      ([selector, cssProps]) =>
        [
          textContentSelectorToCSSSelector(selector as TextContentSelector),
          getStaticDeclarations(cssProps?.css ?? {}),
        ] as const
    );

  return Object.fromEntries(entries);
};

const TEXT_CONTENT_SELECTOR_REGEX = /(line|word|word-in-line):(each|first|last|odd|even|\d+)/;

const textContentSelectorToCSSSelector = (selector: TextContentSelector) => {
  const match = TEXT_CONTENT_SELECTOR_REGEX.exec(selector.trim()) as
    | null
    | [TextContentSelector, TextContentSelectorA, TextContentSelectorB];

  if (!match) {
    console.warn(`Unknown selector ${selector}`);
    return;
  }

  const [_, type, subtype] = match;

  const cssSelector = `span.json-template-${type}`;
  if (subtype === 'odd') return `${cssSelector}-odd`;
  if (subtype === 'even') return `${cssSelector}-even`;
  if (subtype === 'first') return `${cssSelector}-first`;
  if (subtype === 'last') return `${cssSelector}-last`;
  if (!Number.isNaN(Number(subtype))) return `${cssSelector}-${subtype}`;
  return cssSelector;
};

const getStaticDeclarations = (cssProps: AllowedProperties & CSSVariables) => {
  return Object.fromEntries(
    Object.entries(cssProps)
      .filter(([propOrVar]) => (isCSSProperty(propOrVar) && isAllowedProperty(propOrVar)) || isCSSVariable(propOrVar))
      .filter(([, value]) => !isEmptyValue(value))
  );
};

const getAnimationsDeclarations = (
  styleProps: StyleProperties,
  durationInMs: number,
  id: string
): AnimationsDeclarations => {
  return (Object.entries(styleProps.animations ?? {}) as [AnimatedProperty, AnimatedValue][])
    .filter(([property]) => isAnimatedProperty(property))
    .map(([property, value]) => [property, asTimelines(value)] as const)
    .flatMap(([property, value]) => value.map(toAnimationsDeclarations(durationInMs, id, property)))
    .reduce(joinAnimationsDeclarations, { animation: undefined });
};
