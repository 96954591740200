import { type FC, Fragment, useEffect, useState } from 'react';
import { continueRender, delayRender } from 'remotion';

import { type SceneImage as SceneImageDescription, sceneImageDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { loadImage } from '../../../../../../helpers/loadImage';
import { FullSizeFlexContainer } from '../../../../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../../../../containers/UnstylableContainer';
import { Sequence } from '../../../../../remotion/Sequence';
import { SceneImageElement } from './SceneImageElement';

export type SceneImageProps = {
  from: number;
  index: number;
  sceneImage: SceneImageDescription;
};

export const SceneImage: FC<SceneImageProps> = ({ from, index, sceneImage }) => {
  const { template, renderDescription, fps } = useCofensterVideo();
  const [dimensions, setDimensions] = useState<{ height: number; width: number } | undefined>();
  const [handle] = useState(() => delayRender());

  const Container = template.sceneImage?.container || FullSizeFlexContainer;
  const Wrapper = template.sceneImage?.wrapper || Fragment;

  useEffect(() => {
    loadImage(sceneImage.srcUrl).then(({ height, width }) => {
      setDimensions({ height, width });
      continueRender(handle);
    });
  }, [sceneImage.srcUrl, handle]);

  return (
    <Sequence name={`SceneImage-${sceneImage.id}`} from={from} durationInFrames={sceneImageDuration(sceneImage, fps)}>
      <UnstylableContainer className={`scene-image scene-image-${index}`}>
        <Container>
          <Wrapper>
            <SceneImageElement
              src={sceneImage.srcUrl}
              background={renderDescription.background}
              transform={sceneImage.transform}
              width={dimensions?.width}
              height={dimensions?.height}
            />
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </Sequence>
  );
};
