import styled from '@emotion/styled';
import hexRgb from 'hex-rgb';
import type { FC, PropsWithChildren } from 'react';
import rgbHex from 'rgb-hex';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  color: string;
  property: NonNullable<AnimateColorProps['property']>;
};
const Container = styled('div')<ContainerProps>(({ color, property }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    [property]: color,
  };
});

type AnimateColorProps = Omit<UseAnimateConfig, 'fromValue' | 'toValue'> & {
  in?: Omit<UseAnimateConfig['in'], 'fromValue' | 'toValue'>;
  out?: Omit<UseAnimateConfig['out'], 'fromValue' | 'toValue'>;
  className?: string;
  property?: 'color' | 'backgroundColor' | 'borderColor';
  fromColor: string;
  toColor: string;
};

export const AnimateColor: FC<PropsWithChildren<AnimateColorProps>> = ({
  className,
  children,
  fromColor,
  toColor,
  property = 'color',
  ...animateConfig
}) => {
  const value = useAnimate({
    fromValue: 0,
    toValue: 1,
    ...animateConfig,
  });

  const fromColorRgb = hexRgb(fromColor, { format: 'array' });
  const toColorRgb = hexRgb(toColor, { format: 'array' });
  const colorRgb: [number, number, number, number] = [
    (toColorRgb[0] - fromColorRgb[0]) * value + fromColorRgb[0],
    (toColorRgb[1] - fromColorRgb[1]) * value + fromColorRgb[1],
    (toColorRgb[2] - fromColorRgb[2]) * value + fromColorRgb[2],
    (toColorRgb[3] - fromColorRgb[3]) * value + fromColorRgb[3],
  ];
  const color = `#${rgbHex(...colorRgb)}`;

  return (
    <Container className={`animate animate-color ${className ?? ''}`} color={color} property={property}>
      {children}
    </Container>
  );
};
