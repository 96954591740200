import styled from '@emotion/styled';
import type { FC, JSX, PropsWithChildren } from 'react';

import { AdditionalSVGContent } from './AdditionalSVGContent';
import type { StyleDefinition } from './types';
import { useStyleDeclaration } from './useStyleDeclaration';
import { type TemplateDefinition, useTemplateDefinition } from './useTemplateDefinition';
import { useVideoFormat } from './useVideoFormat';

export const withStyleDefinition = <T extends JSX.IntrinsicAttributes>(
  Component: FC<PropsWithChildren<T>>,
  getStyleDefinition: (t: TemplateDefinition, p: T) => StyleDefinition | undefined
): FC<PropsWithChildren<T>> =>
  function ComponentWithStyleDefinition({ ...props }) {
    const templateDefinition = useTemplateDefinition();
    const videoFormat = useVideoFormat();

    const { Horizontal, Vertical, SocialMedia, Square, ...styleProps } =
      getStyleDefinition(templateDefinition, props) ?? ({} as StyleDefinition);

    const styleDeclaration = useStyleDeclaration(styleProps);
    const responsiveStyleDeclarations = useStyleDeclaration(
      {
        Horizontal,
        Vertical,
        SocialMedia,
        Square,
      }[videoFormat]
    );

    if (isEmptyStyleProperties(styleDeclaration)) {
      return <Component {...props} noStyleDefinition />;
    }

    const ComponentWithStyle = styled(Component)({
      ...styleDeclaration,
      [`.format-${videoFormat.toLowerCase()} &`]: responsiveStyleDeclarations,
    });

    return (
      <ComponentWithStyle {...props}>
        {props.children}
        <AdditionalSVGContent definition={styleProps.svg} />
      </ComponentWithStyle>
    );
  };

const isEmptyStyleProperties = (value: object) => {
  return !Object.entries(value).some(([k, v]) => Boolean(k) && Boolean(v));
};
