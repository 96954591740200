import type { CSSProperties } from 'react';

import type { RenderDescription } from '@cofenster/render-description';

import { AnimatedCSSProperties } from './AnimatedCSSProperties';
import { IllegalCSSProperties } from './IllegalCSSProperties';

declare module 'react' {
  interface CSSProperties {
    d?: string;
  }
}

export type Easing =
  | 'linear'
  | `linear(${string})`
  | 'ease'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | `cubic-bezier(${string})`;

export type VideoFormat = RenderDescription['format']['name'];
export type Responsive<T extends object> = T & Partial<Record<VideoFormat, T>>;
export type StyleDefinition = Responsive<StyleProperties>;
export type TextStyleDefinition = Responsive<WithTextContentSelector<StyleProperties>>;

export type TextContentSelectorA = 'line' | 'word' | 'word-in-line';
export type TextContentSelectorB = 'each' | 'first' | 'last' | 'odd' | 'even' | `${number}`;
export type TextContentSelector = `${TextContentSelectorA}:${TextContentSelectorB}`;

export type WithTextContentSelector<T extends object> = T & {
  selectors?: Partial<Record<TextContentSelector, { css: StaticDeclarations }>>;
};

export type StyleProperties = {
  css?: AllowedProperties & CSSVariables;
  animations?: AnimatedProperties & Metadata;
  svg?: SVGDefinition;
};

export type SVGElementStyleProperties = Omit<StyleProperties, 'svg'> & { viewBox?: string };
export type SVGPathStyleProperties = Omit<StyleProperties, 'svg'>;
export type SVGDefinition = {
  element?: SVGElementStyleProperties;
  [key: `path_${number}`]: SVGPathStyleProperties;
};

export type StaticDeclarations = AllowedProperties & CSSVariables;

export type CSSProperty = keyof CSSProperties;
export type CSSVariable = `--${string}`;
export type CSSVariables = Record<CSSVariable, CSSValue>;

export type IllegalProperty = (typeof IllegalCSSProperties)[number];
export type CSSValue = string | number | undefined;
export type Keyframes<V extends CSSValue = CSSValue> = Record<`${number}`, { v: V; e?: Easing }>;
export type Timeline<V extends CSSValue = CSSValue> = Keyframes<V> & Metadata;
export type Timelines<V extends CSSValue = CSSValue> = Timeline<V>[];
export type Metadata = { __metadata?: unknown };
export type AnimatedValue<V extends CSSValue = CSSValue> = Timeline<V> | Timelines<V>;
export type AllowedProperties = Omit<CSSProperties, IllegalProperty>;
export type AllowedProperty = keyof AllowedProperties;

export type AnimatedProperties = {
  [P in (typeof AnimatedCSSProperties)[number]]?: AnimatedValue<AllowedProperties[P]>;
};
export type AnimatedProperty = keyof AnimatedProperties;

export type KeyframesDeclarations = Record<`${number}%` | 'from' | 'to', CSSProperties & CSSVariables>;

export type AnimationsDeclarations = {
  animation: CSSValue;
  [K: `@keyframes ${string}`]: KeyframesDeclarations;
};

/* type helpers */
export const isCSSProperty = (property: string): property is CSSProperty => property in document.body.style;

const isIllegalProperty = (property: CSSProperty): property is IllegalProperty =>
  IllegalCSSProperties.includes(property as IllegalProperty);

export const isAllowedProperty = (property: CSSProperty): property is AllowedProperty => !isIllegalProperty(property);

export const isAnimatedProperty = (property: CSSProperty): property is AnimatedProperty =>
  AnimatedCSSProperties.includes(property as AnimatedProperty);

export const isCSSVariable = (property: string): property is CSSVariable => property.startsWith('--');

export const skipMetadata = <M extends Metadata>({ __metadata, ...object }: M): Omit<M, keyof Metadata> => object;

export const isEmptyValue = <V extends CSSValue = CSSValue>(value: V): value is Exclude<V, undefined> =>
  value === '' || typeof value === 'undefined';
export const asTimelines = <V extends CSSValue = CSSValue>(value: AnimatedValue<V>): Timelines<V> => [value].flat(1);
