import { type FC, useMemo } from 'react';

import type { SceneAsset } from '@cofenster/render-description';
import { SceneAssetsLayer } from './SceneAssetsLayer';

const useAssetLayerIndexes = (sceneAssets: SceneAsset[]) =>
  useMemo(() => {
    // Get all layer indexes for the current scene and sort them
    // from low to high to ensure proper stacking (z-index)
    return Array.from(
      sceneAssets.reduce((set, sceneAsset) => set.add(sceneAsset.layerIndex ?? 1), new Set<number>())
    ).sort((a, b) => a - b);
  }, [sceneAssets]);

const getSceneAssetsByLayerIndex = (sceneAssets: SceneAsset[], layerIndex: number) =>
  sceneAssets.filter((sceneAsset) => sceneAsset.layerIndex === layerIndex);

export type SceneAssetsProps = {
  sceneAssets: SceneAsset[];
  sceneIndex: number;
};

export const SceneAssets: FC<SceneAssetsProps> = ({ sceneAssets, sceneIndex }) => {
  const layerIndexes = useAssetLayerIndexes(sceneAssets);

  return layerIndexes.map((layerIndex) => {
    const assetsForLayer = getSceneAssetsByLayerIndex(sceneAssets, layerIndex);

    return (
      <SceneAssetsLayer key={layerIndex} sceneIndex={sceneIndex} layerIndex={layerIndex} sceneAssets={assetsForLayer} />
    );
  });
};
