import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  revealFrom: NonNullable<FeatheredRevealProps['revealFrom']>;
  featherSize: FeatheredRevealProps['featherSize'];
  maskWidth: number;
};

const Container = styled('div')<ContainerProps>(({ revealFrom, featherSize, maskWidth }) => {
  const gradientDirection = {
    top: 'to bottom',
    bottom: 'to top',
    left: 'to right',
    right: 'to left',
  }[revealFrom];

  const maskPosition = {
    top: 'top left',
    bottom: 'bottom left',
    left: 'top left',
    right: 'top right',
  }[revealFrom];

  const maskSize = {
    top: `100% ${maskWidth ? `calc(${maskWidth}% + ${featherSize}px)` : '0%'}`,
    bottom: `100% ${maskWidth ? `calc(${maskWidth}% + ${featherSize}px)` : '0%'}`,
    left: `${maskWidth ? `calc(${maskWidth}% + ${featherSize}px)` : '0%'} 100%`,
    right: `${maskWidth ? `calc(${maskWidth}% + ${featherSize}px)` : '0%'} 100%`,
  }[revealFrom];

  return {
    display: 'inline-block',
    position: 'relative',
    maskImage: `linear-gradient(${gradientDirection}, white calc(100% - ${featherSize}px), transparent)`,
    maskRepeat: 'no-repeat',
    maskPosition,
    maskSize,
  };
});

type FeatheredRevealProps = Omit<UseAnimateConfig, 'fromValue' | 'toValue'> & {
  in?: Omit<UseAnimateConfig['in'], 'fromValue' | 'toValue'>;
  out?: Omit<UseAnimateConfig['out'], 'fromValue' | 'toValue'>;
  className?: string;
  revealFrom?: 'top' | 'right' | 'bottom' | 'left';
  featherSize: number;
};

export const FeatheredReveal: FC<PropsWithChildren<FeatheredRevealProps>> = ({
  className,
  children,
  revealFrom = 'left',
  featherSize,
  ...animateConfig
}) => {
  const value = useAnimate({
    fromValue: 0,
    toValue: 100,
    ...animateConfig,
  });
  return (
    <Container
      className={`feathered-reveal feathered-reveal-${revealFrom} ${className ?? ''}`}
      featherSize={featherSize}
      revealFrom={revealFrom}
      maskWidth={value}
    >
      {children}
    </Container>
  );
};
