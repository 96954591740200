import { type FC, Fragment } from 'react';

import { type SceneVideo as SceneVideoDescription, sceneVideoDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../../../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../../../../containers/UnstylableContainer';
import { Sequence } from '../../../../../remotion/Sequence';
import { SceneVideoSection } from './SceneVideoSection';
import { SceneVideoSections } from './SceneVideoSections';

export type SceneVideoProps = {
  from: number;
  index: number;
  sceneVideo: SceneVideoDescription;
};

export const SceneVideo: FC<SceneVideoProps> = ({ from, index, sceneVideo }) => {
  const { fps, template, renderDescription } = useCofensterVideo();

  const Container = template.sceneVideo?.container || FullSizeFlexContainer;
  const Wrapper = template.sceneVideo?.wrapper || Fragment;

  return (
    <Sequence name={`SceneVideo-${sceneVideo.id}`} from={from} durationInFrames={sceneVideoDuration(sceneVideo, fps)}>
      <UnstylableContainer className={`scene-video scene-video-${index}`}>
        <Container>
          <Wrapper>
            {sceneVideo.sections?.length ? (
              <SceneVideoSections sceneVideo={sceneVideo} background={renderDescription.background} />
            ) : (
              <SceneVideoSection sceneVideo={sceneVideo} background={renderDescription.background} />
            )}
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </Sequence>
  );
};
