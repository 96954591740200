import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  opacity: number;
};

const Container = styled('div')<ContainerProps>(({ opacity }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    opacity,
  };
});

type AnimateOpacityProps = UseAnimateConfig & { className?: string };

export const AnimateOpacity: FC<PropsWithChildren<AnimateOpacityProps>> = ({
  className,
  children,
  ...animateConfig
}) => {
  const opacity = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-opacity ${className ?? ''}`} opacity={opacity}>
      {children}
    </Container>
  );
};
