import type { CSSProperties, ElementType, FC, PropsWithChildren } from 'react';
import { useCurrentFrame, useVideoConfig } from 'remotion';

import { framesToSeconds } from '@cofenster/render-description';

import { FullSizeFlexContainer } from '../containers/FullSizeFlexContainer';

export const CSSAnimationTimeContainer: FC<
  PropsWithChildren<{
    as?: ElementType;
    className?: string;
  }>
> = ({ children, as, className }) => {
  const currentFrame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const Component = as || FullSizeFlexContainer;

  return (
    <Component
      style={
        {
          '--animation-delay': `calc(-1 * ${framesToSeconds(currentFrame, fps)} * 1000ms)`,
        } as CSSProperties
      }
      className={className}
    >
      {children}
    </Component>
  );
};
