import styled from '@emotion/styled';
import {
  type CSSProperties,
  type FC,
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { AssetTransform } from '@cofenster/render-description';

const CenterContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}));

const TransformContainer = styled('div')(() => ({
  position: 'relative',
  top: 'var(--top)',
  left: 'var(--left)',
  transform: 'var(--transform)',
  clipPath: 'var(--clip-path)',
  width: 'var(--width)',
  height: 'var(--height)',
}));

export type AssetTransformProps = {
  transform?: AssetTransform;
  width?: number | null;
  height?: number | null;
};

// This generateClipPath act inside a CropContainer
// On the TransformContainer we apply scaleX(-1) if flipHorizontal = true
// For this reason, we need to switch cropLeft and cropRight if flipHorizontal is enabled
const generateClipPath = ({ cropBottom, cropLeft, cropRight, cropTop, flipHorizontal }: NonNullable<AssetTransform>) =>
  `inset(${cropTop}% ${flipHorizontal ? cropLeft : cropRight}% ${cropBottom}% ${
    flipHorizontal ? cropRight : cropLeft
  }%)`;

// Since we're applying scaleX(-1), if flipHorizontal = true, then we need to compensate the rotation
const serializeTransform = (transform: NonNullable<AssetTransform>) =>
  `${transform.flipHorizontal ? 'scaleX(-1) ' : ''} scale(${transform.scale}) rotate(${
    transform.flipHorizontal ? -transform.rotation : transform.rotation
  }deg)`;

const computeFitDimensions = (aspectRatio: number, container: HTMLElement) => {
  const containerWidth = container.offsetWidth;
  const containerHeight = container.offsetHeight;
  const containerAspectRatio = containerWidth / containerHeight;

  let height = containerHeight;
  let width = containerHeight * aspectRatio;

  if (aspectRatio > containerAspectRatio) {
    height = containerWidth / aspectRatio;
    width = containerWidth;
  }

  return { height, width };
};

const useAdjustedDimensions = (
  width: AssetTransformProps['width'],
  height: AssetTransformProps['height'],
  containerRef: RefObject<HTMLDivElement>
) => {
  const [dimensions, setDimensions] = useState<{ height: number; width: number } | undefined>();

  useEffect(() => {
    // The container (`containerRef.current`) is sized after its content, which
    // can be an image or a video. If the video is still loading by the time we
    // attempt to compute its dimensions, an incorrect size gets reported and
    // the video will appear smaller inside the content area. To work around the
    // problem, we make sure to recompute the dimensions of the transform
    // container every time the size of the parent container changes (such as
    // when the video finishes loading).
    // See: https://www.notion.so/cofenster/CoManager-Cropping-Video-appears-to-be-already-cropped-to-a-smaller-size-60bc5371f188459aa7c470d5b095ef01
    if (containerRef.current && width && height) {
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const ratio = width / height;
          const container = entry.target as HTMLElement;
          const dimensions = computeFitDimensions(ratio, container);
          setDimensions(dimensions);
        });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [containerRef, width, height]);

  return dimensions;
};

export const AssetTransformContainer: FC<PropsWithChildren<AssetTransformProps>> = ({
  width,
  height,
  transform,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useAdjustedDimensions(width, height, containerRef);

  const styles = useMemo(
    () =>
      (transform
        ? {
            '--top': `${transform.top}%`,
            '--left': `${transform.left}%`,
            '--transform': serializeTransform(transform),
            '--clip-path': generateClipPath(transform),
            '--width': dimensions?.width ? `${dimensions.width}px` : '100%',
            '--height': dimensions?.height ? `${dimensions.height}px` : '100%',
          }
        : {}) as CSSProperties,
    [transform, dimensions]
  );

  if (!transform) return <>{children}</>;

  return (
    <CenterContainer ref={containerRef}>
      <TransformContainer style={styles}>{children}</TransformContainer>
    </CenterContainer>
  );
};
