import { AWS_AUDIO_KEY_NOT_APPLICABLE, LAYER_INDEXES } from '@cofenster/constants';
import {
  type SceneAudio,
  type SceneVideo,
  sceneDuration,
  sceneVideoSectionDuration,
  secondsToFrames,
} from '@cofenster/render-description';
import type { RenderDescription } from '@cofenster/render-worker';

export type DuckingSource = {
  type: 'audio' | 'video';
  // in frames
  start: number;
  // in frames
  duration: number;
  loudnessUrl: string;
  // in frames
  assetOffset?: number;
};

export const getAudioDuckingSources = (rd: RenderDescription): DuckingSource[] => {
  const fps = rd.format.fps ?? 30;

  const mediaAssets: DuckingSource[] = [];

  let offset = 0;
  if (rd.intro?.duration) {
    offset += secondsToFrames(rd.intro.duration, fps);
  }

  for (const scene of rd.scenes) {
    const duration = sceneDuration(scene, fps);

    for (const asset of scene.sceneAssets) {
      if (asset.type === 'video') {
        const videoAsset = asset as SceneVideo;
        if (videoAsset.volume === 0) continue;
        if (!videoAsset.audioLoudnessUrl) continue;
        if (videoAsset.audioLoudnessUrl === AWS_AUDIO_KEY_NOT_APPLICABLE) continue;

        if (videoAsset.sections?.length) {
          let sectionOffset = 0;
          for (const section of videoAsset.sections) {
            const sectionDuration = sceneVideoSectionDuration(section, fps);
            mediaAssets.push({
              type: 'video',
              start: offset + sectionOffset,
              duration: sectionDuration,
              loudnessUrl: videoAsset.audioLoudnessUrl,
              assetOffset: secondsToFrames(section.offsetIn, 30),
            });
            sectionOffset += sectionDuration;
          }

          if (videoAsset.layerIndex === LAYER_INDEXES.main) {
            console.assert(sectionOffset === duration, 'Total duration mismatch');
          }
        } else {
          mediaAssets.push({
            type: 'video',
            start: offset + secondsToFrames(videoAsset.startTime ?? 0, fps),
            duration: secondsToFrames(videoAsset.duration, fps),
            loudnessUrl: videoAsset.audioLoudnessUrl,
          });
        }
      }

      if (asset.type === 'audio') {
        const audioAsset = asset as SceneAudio;
        if (audioAsset.volume === 0) continue;
        if (!audioAsset.audioLoudnessUrl) continue;
        if (audioAsset.audioLoudnessUrl === AWS_AUDIO_KEY_NOT_APPLICABLE) continue;
        mediaAssets.push({
          type: 'audio',
          start: offset + secondsToFrames(audioAsset.startTime ?? 0, fps),
          // do not use sceneAssetDuration here, it returns 0 for audio
          duration: secondsToFrames(asset.duration, fps),
          loudnessUrl: audioAsset.audioLoudnessUrl,
        });
      }
    }

    offset += duration;
  }

  if (rd.outro?.duration) {
    offset += secondsToFrames(rd.outro.duration, fps);
  }

  console.assert(offset === rd.totalDurationInFrames, 'Total duration mismatch');

  __DEBUG_AUDIO_DUCKING__ && console.log('getAudioDuckingSources', mediaAssets);

  return mediaAssets;
};
