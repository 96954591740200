import type { SceneAsset } from '../types/RenderDescription';

import { isSceneAudio } from './isSceneAudio';
import { isSceneColor } from './isSceneColor';
import { isSceneImage } from './isSceneImage';
import { isSceneVideo } from './isSceneVideo';
import { sceneAudioDuration } from './sceneAudioDuration';
import { sceneColorDuration } from './sceneColorDuration';
import { sceneImageDuration } from './sceneImageDuration';
import { sceneVideoDuration } from './sceneVideoDuration';

export const sceneAssetDuration = (sceneAsset: SceneAsset, fps: number, ignoreSections = false) => {
  if (isSceneVideo(sceneAsset)) {
    return sceneVideoDuration(sceneAsset, fps, ignoreSections);
  }
  if (isSceneImage(sceneAsset)) {
    return sceneImageDuration(sceneAsset, fps);
  }
  if (isSceneColor(sceneAsset)) {
    return sceneColorDuration(sceneAsset, fps);
  }
  if (isSceneAudio(sceneAsset)) {
    return sceneAudioDuration(sceneAsset, fps);
  }

  throw new Error('Unknown SceneAsset type');
};
