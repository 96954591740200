import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { type FC, Fragment } from 'react';

import { introOutroDuration, scenesDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { Image } from '../../remotion/Image';
import { Sequence } from '../../remotion/Sequence';

const LogoContainer = styled(FullSizeFlexContainer)(() => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  margin: 50,
  pointerEvents: 'none',
}));

const StyledImage = styled(Image)(() => ({
  maxWidth: 300,
  maxHeight: 100,
  objectFit: 'contain',
}));

export const Logo: FC = () => {
  const { fps, template, renderDescription } = useCofensterVideo();
  const { logo, intro } = renderDescription;

  if (!logo) return null;

  const durationIntro = intro ? introOutroDuration(intro, fps) : 0;
  const durationScenes = scenesDuration(renderDescription.scenes, fps);
  if (durationScenes === 0) return null;

  const Container = template.logo?.container || LogoContainer;
  const Wrapper = template.logo?.wrapper || Fragment;
  const Element = template.logo?.element || StyledImage;

  return (
    <>
      <Sequence name="Logo" from={durationIntro} durationInFrames={durationScenes}>
        <UnstylableContainer className="logo align-left">
          <Container className="logo-container">
            <Wrapper>
              <Element className="logo-image" src={logo.srcUrl} alt="" />
            </Wrapper>
          </Container>
        </UnstylableContainer>
      </Sequence>
      <Global
        styles={{
          '.cofenster-video': {
            '--logo-url': `url(${logo.srcUrl})`,
          },
        }}
      />
    </>
  );
};
