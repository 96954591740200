import { type FC, Fragment } from 'react';
import { Series } from 'remotion';

import { introOutroDuration, sceneDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { Intro } from '../Intro';
import { Outro } from '../Outro';
import { Scene } from '../Scenes/Scene';

export const ScenesWithIntroOutro: FC = () => {
  const { template, fps, renderDescription } = useCofensterVideo();
  const { scenes, intro, outro } = renderDescription;

  const Container = template.scenes?.container || FullSizeFlexContainer;
  const Wrapper = template.scenes?.wrapper || Fragment;

  return (
    <UnstylableContainer className="scenes">
      <Container className="scenes-container">
        <Wrapper>
          <Series>
            {intro && (
              <Series.Sequence durationInFrames={introOutroDuration(intro, fps)}>
                <Intro />
              </Series.Sequence>
            )}

            {scenes.map((sceneDescription, sceneIndex) => (
              <Series.Sequence key={sceneDescription.id} durationInFrames={sceneDuration(sceneDescription, fps)}>
                <Scene sceneIndex={sceneIndex} sceneDescription={sceneDescription} />
              </Series.Sequence>
            ))}

            {outro && (
              <Series.Sequence durationInFrames={introOutroDuration(outro, fps)}>
                <Outro />
              </Series.Sequence>
            )}
          </Series>
        </Wrapper>
      </Container>
    </UnstylableContainer>
  );
};
