/**
 * Only properties not causing reflow
 */
export const AnimatedCSSProperties = [
  'transform',
  'transformOrigin',
  'opacity',
  'color',
  'backgroundColor',
  'borderColor',
  'borderRadius',
  'clipPath',
  'filter',
  'perspective',
  'perspectiveOrigin',
] as const;
