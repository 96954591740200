import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';
import type { DimensionUnit } from '../types';

type ContainerProps = NonNullable<Pick<AnimateMarginTopProps, 'unit'>> & {
  marginTop: number | string;
};

const Container = styled('div')<ContainerProps>(({ marginTop, unit }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    marginTop: `${marginTop}${unit}`,
  };
});

type AnimateMarginTopProps = UseAnimateConfig & {
  className?: string;
  unit?: DimensionUnit;
};

export const AnimateMarginTop: FC<PropsWithChildren<AnimateMarginTopProps>> = ({
  className,
  children,
  unit = '%',
  ...animateConfig
}) => {
  const marginTop = useAnimate(animateConfig);

  return (
    <Container className={`animate animate-margin-top ${className ?? ''}`} marginTop={marginTop} unit={unit}>
      {children}
    </Container>
  );
};
