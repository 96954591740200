import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';
import { AnimateMotionBlur } from '../AnimateMotionBlur';
import type { DimensionUnit } from '../types';

type ContainerProps = NonNullable<Pick<AnimateTranslateXProps, 'unit'>> & {
  x: number;
};
const Container = styled('div')<ContainerProps>(({ x, unit }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    transform: `translateX(${x}${unit})`,
  };
});

type AnimateTranslateXProps = UseAnimateConfig & {
  className?: string;
  unit?: DimensionUnit;
  blurred?: boolean;
};

export const AnimateTranslateX: FC<PropsWithChildren<AnimateTranslateXProps>> = ({
  className,
  children,
  unit = '%',
  blurred = false,
  ...animateConfig
}) => {
  const x = useAnimate(animateConfig);
  const element = (
    <Container className={`animate animate-translate-x ${className ?? ''}`} unit={unit} x={x}>
      {children}
    </Container>
  );

  if (blurred) {
    return (
      <AnimateMotionBlur {...animateConfig} fromValue={50}>
        {element}
      </AnimateMotionBlur>
    );
  }

  return element;
};
