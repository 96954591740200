import { useContext, useEffect } from 'react';
import { prefetch } from 'remotion';

import type { RenderDescription } from '@cofenster/render-description';
import { AssetPreloadingContext } from './AssetPreloadingContext';
import { useUniqueUrls } from './useUniqueUrls';

// This hook retrieves all the URLs from the render description (images, videos
// and audios, including from the intro and outro), and prefetches them with
// Remotion’s built-in prefetcher. It maintains which URL was prefetched in a
// cache exposed outside of Remotion in order to be persisted across renders and
// mountings and unmountings of the player.
export const useAssetPreloading = (renderDescription: RenderDescription) => {
  const urls = useUniqueUrls(renderDescription);
  const cache = useContext(AssetPreloadingContext);

  useEffect(() => {
    // See: https://www.remotion.dev/docs/prefetch
    urls.forEach((url) => {
      // If the URL already exists in the map, it means it has been either fully
      // prefetched (true) or started prefetching (false). In any case, we
      // should not try prefetching it again as it would be wasteful.
      if (cache.has(url)) return;

      // Mark the URL as started prefetching.
      cache.set(url, false);

      // Prefetch the URL with Remotion and when fully done, mark the URL as
      // being fully prefetched if it succeeded, or remove it from the map so
      // it can be retried.
      prefetch(url)
        .waitUntilDone()
        .then(() => cache.set(url, true))
        .catch(() => cache.delete(url));
    });
  }, [urls, cache]);
};
