import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// biome-ignore lint/suspicious/noExplicitAny: can legitimately be anything
const identity = (value: any) => value;

export function useUrlParameter<T>(
  initialState: T,
  paramName: string,
  serialize: (state: T) => string = identity,
  deserialize: (state: string) => T = identity
): [T, (state: T) => void] {
  const navigate = useNavigate();
  const { search } = useLocation();
  const currentValue = new URLSearchParams(search).get(paramName);
  const [state, setState] = useState<T>(currentValue ? deserialize(currentValue) : initialState);
  const serializedInitialState = useMemo(() => serialize(initialState), [serialize, initialState]);

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (currentValue && currentValue !== serialize(state)) {
      setState(deserialize(currentValue));
    }
  }, [currentValue, deserialize, serialize, state]);

  const onChange = useCallback(
    (value: T) => {
      const serializedValue = serialize(value);
      const params = new URLSearchParams(search);

      if (serializedValue !== '' && serializedValue !== serializedInitialState) {
        params.set(paramName, serializedValue);
      } else {
        params.delete(paramName);
      }

      setState(value);
      navigate(`?${params.toString()}`);
    },
    [search, navigate, paramName, serialize, serializedInitialState]
  );

  return useMemo(() => [state, onChange], [state, onChange]);
}
