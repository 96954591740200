import BezierEasing from 'bezier-easing';
import { useCurrentFrame, useVideoConfig } from 'remotion';

import { secondsToFrames } from '@cofenster/render-description';
import { type CubicBezier, type EasingFunction, type EasingPresets, presets } from '../easing';

const DEFAULTS = {
  easing: 'easeInOutSine' as EasingPresets | CubicBezier | EasingFunction,
  fromValue: 0,
  toValue: 1,
  startTime: 0,
  duration: 0.5,
};

type Defaults = typeof DEFAULTS;

export type UseAnimateConfig = Partial<Defaults> & {
  in?: boolean | Partial<Defaults>;
  out?: boolean | (Partial<Defaults> & { startTime: Defaults['startTime'] });
};

export const useAnimate = (config: UseAnimateConfig) => {
  const frame = useCurrentFrame();
  const { durationInFrames, fps } = useVideoConfig();

  const { in: inConfig, out: outConfig, ...mainConfig } = config;

  const inSettings =
    inConfig === false
      ? false
      : inConfig === true
        ? {
            ...DEFAULTS,
            ...mainConfig,
          }
        : {
            ...DEFAULTS,
            ...mainConfig,
            ...inConfig,
          };
  if (inSettings) {
    inSettings.duration = secondsToFrames(inSettings.duration, fps);
    inSettings.startTime = secondsToFrames(inSettings.startTime, fps);
    if (inSettings.startTime < 0) {
      inSettings.startTime = durationInFrames + inSettings.startTime;
    }
  }

  const outSettings =
    outConfig === false
      ? false
      : outConfig === true
        ? {
            ...DEFAULTS,
            ...mainConfig,
            fromValue: mainConfig.toValue ?? DEFAULTS.toValue,
            toValue: mainConfig.fromValue ?? DEFAULTS.fromValue,
          }
        : {
            ...DEFAULTS,
            ...mainConfig,
            fromValue: mainConfig.toValue ?? DEFAULTS.toValue,
            toValue: mainConfig.fromValue ?? DEFAULTS.fromValue,
            ...outConfig,
          };
  if (outSettings) {
    outSettings.duration = secondsToFrames(outSettings.duration, fps);
    outSettings.startTime = secondsToFrames(outSettings.startTime, fps);
    if (outSettings.startTime < 0) {
      outSettings.startTime = durationInFrames + outSettings.startTime;
    }
    if (inSettings && outSettings.startTime < inSettings.startTime + inSettings.duration) {
      outSettings.startTime = inSettings.startTime + inSettings.duration;
    }
  }

  if (inSettings) {
    if (frame < inSettings.startTime) {
      return inSettings.fromValue;
    }
    if (frame >= inSettings.startTime + inSettings.duration) {
      if (!outSettings || frame < outSettings.startTime) {
        return inSettings.toValue;
      }
    } else {
      const elapsed = frame - inSettings.startTime;
      const percentage = elapsed / inSettings.duration;
      const easingFunction =
        typeof inSettings.easing === 'string'
          ? presets[inSettings.easing]
          : typeof inSettings.easing === 'function'
            ? inSettings.easing
            : BezierEasing(...inSettings.easing);
      const easedPercentage = easingFunction(percentage);
      return inSettings.fromValue + (inSettings.toValue - inSettings.fromValue) * easedPercentage;
    }
  }

  if (outSettings) {
    if (frame < outSettings.startTime) {
      if (inSettings) {
        return inSettings.toValue;
      }
      return outSettings.fromValue;
    }
    if (frame >= outSettings.startTime + outSettings.duration) {
      return outSettings.toValue;
    }
    const elapsed = frame - outSettings.startTime;
    const percentage = elapsed / outSettings.duration;
    const easingFunction =
      typeof outSettings.easing === 'string'
        ? presets[outSettings.easing]
        : typeof outSettings.easing === 'function'
          ? outSettings.easing
          : BezierEasing(...outSettings.easing);
    const easedPercentage = easingFunction(percentage);
    return outSettings.fromValue + (outSettings.toValue - outSettings.fromValue) * easedPercentage;
  }

  throw new Error('Either "in" or "out" must be set');
};
