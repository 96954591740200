import styled from '@emotion/styled';
import { type FC, type PropsWithChildren, useId } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

const Container = styled('div')<{ uniqueId: string }>(({ uniqueId }) => ({
  filter: `url(#horizontal-${uniqueId})`,
}));

const Svg = styled('svg')({
  display: 'none',
});

type AnimateMotionBlurProps = UseAnimateConfig & {
  className?: string;
};

export const AnimateMotionBlur: FC<PropsWithChildren<AnimateMotionBlurProps>> = ({
  className,
  children,
  ...animateConfig
}) => {
  const deviation = useAnimate(animateConfig);
  const uniqueId = useId();

  return (
    <>
      <Svg>
        <filter id={`horizontal-${uniqueId}`}>
          <feGaussianBlur in="SourceGraphic" stdDeviation={`${deviation}, 0`} />
        </filter>
      </Svg>
      <Container uniqueId={uniqueId} className={`animate animate-motion-blur ${className ?? ''}`}>
        {children}
      </Container>
    </>
  );
};
