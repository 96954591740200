import { type FC, useMemo } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  EmptyState,
  GridContainer,
  GridItem,
  ResultPagination,
  Typography,
  formatDateTime,
} from '@cofenster/web-components';

import {
  type ExtendedRenderJob,
  type ExtendedRenderJobsPaginationResult,
  useExportSettings,
} from '../../../../contexts/exports';

import { HeaderCell } from './HeaderCell';
import { JobOptions } from './JobOptions';
import { JobPrimary } from './JobPrimary';
import { JobStatus } from './JobStatus';

const columns: CSSGridTableConfig<ExtendedRenderJob>['columns'] = [
  {
    id: 'id',
    name: 'Project / Account',
    cell: ({ item }) => <JobPrimary {...item} />,
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'type',
    name: 'Type',
    cell: ({ item }) => (
      <Typography variant="m">
        {item.subtitle
          ? 'Subtitle'
          : item.job.subtitlesLanguage
            ? `Subtitles (new - ${item.job.subtitlesLanguage})`
            : 'Project'}
      </Typography>
    ),
    header: (props) => <HeaderCell {...props} withOrdering={false} />,
    extra: { size: 'max-content' },
  },
  {
    id: 'remotionVersion',
    name: 'Remotion',
    cell: ({ item: { job } }) => (job.useNg ? <em>NextGen</em> : 'Current'),
    header: (props) => <HeaderCell {...props} withOrdering={false} />,
  },
  {
    id: 'status',
    name: 'Status',
    cell: ({ item: { job } }) => <JobStatus {...job} />,
    header: HeaderCell,
  },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item: { job } }) => (
      <Typography variant="m" component="time">
        {formatDateTime(job.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
  },
  {
    id: 'updatedAt',
    name: 'Updated at',
    cell: ({ item: { job } }) => (
      <Typography variant="m" component={job.updatedAt ? 'time' : 'span'}>
        {job.updatedAt ? formatDateTime(job.updatedAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
  },
  {
    id: 'options',
    name: '',
    cell: ({ item }) => <JobOptions {...item} />,
    header: (props) => <HeaderCell {...props} withOrdering={false} />,
    extra: { size: 'max-content' },
  },
];

export const ExportsList: FC<{ pagination: ExtendedRenderJobsPaginationResult }> = ({ pagination }) => {
  const { page, setPage } = useExportSettings();

  // Ensure every row has an ID so it has a stable key which is important due to
  // the row ordering
  const data = useMemo(
    () => pagination.items.map((extendedJob) => ({ ...extendedJob, id: extendedJob.job.id })),
    [pagination.items]
  );

  if (pagination.items.length === 0) {
    return (
      <EmptyState
        title="No results"
        description="No exports could be found for these criteria."
        iconType="SearchIcon"
      />
    );
  }

  return (
    <>
      <CSSGridTable<ExtendedRenderJob> data={data} columns={columns} data-testid="render-jobs" />
      <GridContainer justifyContent="center" pt={3}>
        <GridItem>
          <ResultPagination
            total={pagination.total}
            limit={pagination.limit ?? pagination.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
