import type { RenderDescription } from '../types/RenderDescription';

import { introOutroDuration } from './introOutroDuration';
import { scenesDuration } from './scenesDuration';

export const totalVideoDuration = (
  fps: number,
  {
    intro,
    scenes,
    outro,
  }: {
    intro: RenderDescription['intro'];
    scenes: RenderDescription['scenes'];
    outro: RenderDescription['outro'];
  }
) => {
  const durationIntro = intro ? introOutroDuration(intro, fps) : 0;
  const durationScenes = scenesDuration(scenes, fps);
  const durationOutro = outro ? introOutroDuration(outro, fps) : 0;
  return durationIntro + durationScenes + durationOutro;
};
