import { loudnessToDuckingFactor } from '../../../helpers/loudnessToDuckingFactor';
import type { DuckingSource } from './getAudioDuckingSources';

const LOUDNESS_DATA_CACHE = new Map<string, (number | null)[]>();

export const mapFrameToDuckingFactor = async (totalFrames: number, sources: DuckingSource[]) => {
  const loudnessByFrame: number[] = new Array(totalFrames).fill(Number.NEGATIVE_INFINITY);

  await Promise.all(
    Array.from(new Set(sources.map((source) => source.loudnessUrl))).map(async (loudnessUrl) => {
      if (LOUDNESS_DATA_CACHE.has(loudnessUrl)) return;
      const loudnessData = await fetch(loudnessUrl).then((res) => res.json() as Promise<(number | null)[]>);
      LOUDNESS_DATA_CACHE.set(loudnessUrl, loudnessData);
    })
  );

  for (const source of sources) {
    const sourceLoudness = LOUDNESS_DATA_CACHE.get(source.loudnessUrl);
    if (!sourceLoudness) throw new Error('Source loudness not found in cache');

    const startFrame = source.start;

    for (let i = source.assetOffset ?? 0; i < source.duration; i++) {
      if (i >= sourceLoudness.length) throw new Error('Index out of source loudness bounds');
      const assetLoudness = sourceLoudness[i];
      const frame = startFrame + i;
      loudnessByFrame[frame] = Math.max(loudnessByFrame[frame] ?? 0, assetLoudness ?? 0);
    }
  }

  return loudnessToDuckingFactor(loudnessByFrame);
};
