import { Global } from '@emotion/react';
import type { FC } from 'react';

import { useCofensterVideo } from '../../../context/cofensterVideo';

export const Fonts: FC = () => {
  const { renderDescription } = useCofensterVideo();
  const { fonts } = renderDescription;

  if (!fonts?.length) return null;

  return (
    <>
      {fonts.map((font) => {
        return (
          <Global
            key={font.name}
            styles={{
              '@font-face': {
                fontFamily: font.name,
                fontWeight: 'normal',
                fontStyle: 'normal',
                src: `url(${font.srcUrl})`,
              },
            }}
          />
        );
      })}
    </>
  );
};
