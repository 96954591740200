import type { FC } from 'react';

import {
  Button,
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  Icon,
  LoadingSpinner,
  Typography,
  formatDateTime,
} from '@cofenster/web-components';

import {
  type TemplateLatestProject,
  useRenderTemplateLatestProjects,
} from '../../../api/hooks/renderTemplate/useRenderTemplateLatestProjects';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateProject } from '../../../hooks/useImpersonateProject';

const ImpersonateProjectButton: FC<{ projectId: string }> = ({ projectId }) => {
  const impersonateProject = useImpersonateProject(projectId, `/project/${projectId}/video`);

  return (
    <PermissionRestriction has="UserImpersonate">
      <Button variant="tertiary" startIcon={<Icon type="UserSwitchIcon" size="s" />} onClick={impersonateProject}>
        Impersonate
      </Button>
    </PermissionRestriction>
  );
};

const HeaderCell: FC<{ column: ColumnConfig<TemplateLatestProject> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<TemplateLatestProject>['columns'] = [
  {
    id: 'name',
    name: 'Name',
    cell: ({ item }) => item.name,
    header: HeaderCell,
  },
  {
    id: 'videoFormat',
    name: 'Video Format',
    cell: ({ item }) => item.videoFormat,
    header: HeaderCell,
  },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {formatDateTime(item.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
  },
  {
    id: 'impersonate',
    name: 'Impersonate',
    cell: ({ item }) => <ImpersonateProjectButton projectId={item.id} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const TemplateProjects: FC<{ renderTemplateId: string }> = ({ renderTemplateId }) => {
  const { latestProjects, loading } = useRenderTemplateLatestProjects(renderTemplateId);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (latestProjects.length === 0) {
    return (
      <EmptyState
        iconType="SearchIcon"
        title="No projects found"
        description="No projects were found matching for this template."
      />
    );
  }

  return <CSSGridTable<TemplateLatestProject> data={latestProjects} columns={columns} />;
};
