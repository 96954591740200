import styled from '@emotion/styled';
import { type FC, Fragment } from 'react';

import { introOutroDuration, sceneDuration, scenesDuration, secondsToFrames } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { Sequence } from '../../remotion/Sequence';

export const Transitions: FC = () => {
  const { template, fps, renderDescription } = useCofensterVideo();
  const { scenes, intro } = renderDescription;

  const hasTransitions = scenes.some((scene) => scene.withTransition);
  if (!hasTransitions) return null;

  if (!template.transition) {
    console.error('Template has no transition');
    return null;
  }

  const durationIntro = intro ? introOutroDuration(intro, fps) : 0;
  const durationScenes = scenesDuration(scenes, fps);

  const TransitionElement = template.transition.element;
  const Container = template.transition?.container || StyledFullSizeFlexContainer;
  const Wrapper = template.transition?.wrapper || Fragment;

  let current = 0;
  return (
    <Sequence name="Transitions" from={durationIntro} durationInFrames={durationScenes}>
      <UnstylableContainer className="transitions">
        {scenes.map((scene, index, scenes) => {
          if (index >= scenes.length - 1) return null;
          const durationScene = sceneDuration(scene, fps);
          current += durationScene;
          if (!scene.withTransition) return null;
          const from = current - secondsToFrames(0.5, fps);
          const transitionDuration = secondsToFrames(1, fps);
          return (
            <Sequence name={`Transition-${index}`} key={from} from={from} durationInFrames={transitionDuration}>
              <Container className="transitions-container">
                <Wrapper>
                  <TransitionElement />
                </Wrapper>
              </Container>
            </Sequence>
          );
        })}
      </UnstylableContainer>
    </Sequence>
  );
};

const StyledFullSizeFlexContainer = styled(FullSizeFlexContainer)({
  '&:empty': {
    display: 'none',
  },
});
