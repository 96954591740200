import type { FC } from 'react';

import type { SceneText as SceneTextType } from '@cofenster/render-description';

import { SceneText } from './SceneText';

export type SceneTextsProps = {
  sceneTexts: SceneTextType[];
  sceneDurationInFrames: number;
};

export const SceneTexts: FC<SceneTextsProps> = ({ sceneTexts, sceneDurationInFrames }) =>
  sceneTexts.map((sceneText) => (
    <SceneText key={sceneText.id} sceneText={sceneText} sceneDurationInFrames={sceneDurationInFrames} />
  ));
