import { useCallback, useEffect, useState } from 'react';
import { cancelRender, continueRender, delayRender } from 'remotion';

import type { RenderDescription } from '@cofenster/render-worker';

import { getAudioDuckingSources } from './getAudioDuckingSources';
import { mapFrameToDuckingFactor } from './mapFrameToDuckingFactor';
import { useAbortOnUnmount } from './useAbortOnUnmount';

export const useDuckingFactors = (renderDescription: RenderDescription) => {
  const [duckingFactors, setDuckingFactors] = useState<number[] | null>(null);

  const prepare = useCallback(async (renderDescription: RenderDescription, abortSignal: AbortSignal) => {
    const renderHandle = delayRender('BackgroundMusic');
    try {
      const sources = getAudioDuckingSources(renderDescription);
      const frameToFactor = await mapFrameToDuckingFactor(renderDescription.totalDurationInFrames, sources);
      continueRender(renderHandle);
      if (abortSignal.aborted) return;
      setDuckingFactors(frameToFactor.factors);
    } catch (error) {
      console.error(error);
      cancelRender(error);
    }
  }, []);

  const abortSignal = useAbortOnUnmount();

  useEffect(() => {
    prepare(renderDescription, abortSignal);
  }, [prepare, renderDescription, abortSignal]);

  return duckingFactors;
};
