import styled from '@emotion/styled';
import { type FC, Fragment, type ReactNode } from 'react';

import { type SceneText as SceneTextDescription, secondsToFrames } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import type { TextElement } from '../../../../../../template/Template';
import { FullSizeFlexContainer } from '../../../../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../../../../containers/UnstylableContainer';
import { Sequence } from '../../../../../remotion/Sequence';

const TextContainer = styled(FullSizeFlexContainer)(() => ({
  position: 'relative',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
}));

export type SceneTextProps = {
  sceneText?: SceneTextDescription;
  sceneDurationInFrames: number;
};

export const SceneText: FC<SceneTextProps> = ({ sceneDurationInFrames, sceneText }) => {
  const { template, fps } = useCofensterVideo();

  if (!sceneText) return null;

  const textElementName = sceneText.name;

  const TextElement: TextElement | undefined = template.sceneTexts?.[textElementName]?.element;
  const Container = template.sceneTexts?.[textElementName]?.container || TextContainer;
  const Wrapper = template.sceneTexts?.[textElementName]?.wrapper || Fragment;

  if (!TextElement) {
    console.error(`Template does not provide a TextElement for "${textElementName}"`);
    return null;
  }

  const startTimeInFrames = sceneText.startTime > 0 ? secondsToFrames(sceneText.startTime, fps) : 0;
  const durationInFrames =
    sceneText.duration === 0
      ? // A duration of 0 means the text element should be displayed
        // for the entire duration of the scene
        sceneDurationInFrames - startTimeInFrames
      : secondsToFrames(sceneText.duration, fps);

  const texts = sceneText.texts.reduce<Record<string, ReactNode>>(
    (acc, element) => ({
      ...acc,
      [element.name]: (
        <>
          {element.text.split('\n').map((line, index) => {
            return (
              <Fragment key={`${line}:${index}`}>
                {index > 0 && <br />}
                {line}
              </Fragment>
            );
          })}
        </>
      ),
    }),
    {}
  );

  return (
    <Sequence name={`SceneText-${sceneText.name}`} from={startTimeInFrames} durationInFrames={durationInFrames}>
      <UnstylableContainer className={`scene-text scene-text-${textElementName}`}>
        <Container className="scene-text-container">
          <Wrapper>
            <TextElement className="scene-text-element" texts={texts} />
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </Sequence>
  );
};
