import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';
import type { DimensionUnit } from '../types';

type ContainerProps = NonNullable<Pick<AnimateTranslateYProps, 'unit'>> & {
  y: number;
};
const Container = styled('div')<ContainerProps>(({ y, unit }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    transform: `translateY(${y}${unit})`,
  };
});

export type AnimateTranslateYProps = UseAnimateConfig & {
  className?: string;
  unit?: DimensionUnit;
};

export const AnimateTranslateY: FC<PropsWithChildren<AnimateTranslateYProps>> = ({
  className,
  children,
  unit = '%',
  ...animateConfig
}) => {
  const y = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-translate-y ${className ?? ''}`} unit={unit} y={y}>
      {children}
    </Container>
  );
};
