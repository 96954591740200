import { type FC, Fragment } from 'react';

import { type Scene as SceneDescription, sceneDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../context/cofensterVideo';
import { SceneDescriptionProvider } from '../../../../context/sceneDescription';
import { FullSizeFlexContainer } from '../../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../../containers/UnstylableContainer';
import { SceneAssets } from './SceneAssets';
import { SceneTexts } from './SceneTexts';

export type SceneProps = {
  sceneIndex: number;
  sceneDescription: SceneDescription;
};

export const Scene: FC<SceneProps> = ({ sceneIndex, sceneDescription }) => {
  const { fps, template } = useCofensterVideo();
  const durationScene = sceneDuration(sceneDescription, fps);

  const Container = template.scene?.container || FullSizeFlexContainer;
  const Wrapper = template.scene?.wrapper || Fragment;

  const { sceneAssets, sceneTexts } = sceneDescription;

  return (
    <SceneDescriptionProvider sceneDescription={sceneDescription}>
      <UnstylableContainer className={`scene scene-${sceneIndex}`}>
        <Container>
          <Wrapper>
            <SceneAssets sceneAssets={sceneAssets} sceneIndex={sceneIndex} />

            {sceneTexts && <SceneTexts sceneDurationInFrames={durationScene} sceneTexts={sceneTexts} />}
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </SceneDescriptionProvider>
  );
};
