import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Alert,
  Form,
  FormSubmitButton,
  FormTextField,
  FormToggleSwitch,
  GridContainer,
  GridItem,
  Spacing,
  useSnackbars,
} from '@cofenster/web-components';
import { useCreateMusic } from '../../api/hooks/music/useCreateMusic';
import type { Music } from '../../api/hooks/music/useMusic';
import { useUpdateMusic } from '../../api/hooks/music/useUpdateMusic';
import { useGotoMusic } from '../../hooks/useGotoMusic';

import { MusicUpload } from './MusicUpload';
import { PollingAudioAsset } from './MusicUpload/PollingMusicAsset';

type Values = {
  name: string;
  nameEN: string;
  musicAssetId: string;
  unavailable: boolean;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
  musicAssetId: Yup.string().trim().required('Please fill in this field'),
  unavailable: Yup.boolean().required(),
});

const useInitialValues = (music: Music | undefined) => {
  return useMemo<Values>(
    () => ({
      name: music?.name ?? '',
      nameEN: music?.nameEN ?? '',
      musicAssetId: music?.musicAsset?.id ?? '',
      unavailable: music?.unavailable ?? false,
    }),
    [music]
  );
};

const useCreate = () => {
  const createMusic = useCreateMusic();
  const goToMusic = useGotoMusic();
  return useCallback(
    async (data: Values) => {
      try {
        const result = await createMusic(data);
        const newMusic = result.data?.createMusic;
        newMusic && goToMusic(newMusic.id);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [createMusic, goToMusic]
  );
};

const useUpdate = () => {
  const updateMusic = useUpdateMusic();
  return useCallback(
    async (music: Music, data: Values) => {
      try {
        await updateMusic(music.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateMusic]
  );
};

const useSubmit = (music: Music | undefined) => {
  const createMusic = useCreate();
  const updateMusic = useUpdate();
  const gotoMusics = useGotoMusic();
  const { openSnackbar } = useSnackbars();
  return useCallback(
    async (values: Values) => {
      try {
        if (music) {
          await updateMusic(music, values);
          openSnackbar({
            variant: 'success',
            children: 'Saved successfully',
          });
        } else {
          await createMusic(values);
          gotoMusics();
        }
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [music, createMusic, updateMusic, gotoMusics, openSnackbar]
  );
};

type Props = {
  music?: Music | undefined;
};

export const MusicForm: FC<Props> = ({ music }) => {
  const [musicAssetId, setMusicAssetId] = useState<string | undefined>(music?.musicAsset?.id);
  const initialValues = useInitialValues(music);
  const onSubmit = useSubmit(music);
  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField id="musicName" name="name" label="Name (DE)" placeholder="Name (DE)" />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField id="musicNameEN" name="nameEN" label="Name (EN)" placeholder="Name (EN)" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6} mb={3}>
          <PollingAudioAsset audioAssetId={musicAssetId}>
            {(polledAudioAsset) => (
              <MusicUpload
                name="musicAssetId"
                audioAsset={polledAudioAsset}
                musicId={music?.id}
                setMusicAssetId={setMusicAssetId}
              />
            )}
          </PollingAudioAsset>
        </GridItem>
        <GridItem xs={12} md={6} mb={3}>
          <FormToggleSwitch id="unavailable" name="unavailable">
            Unavailable (hidden)
          </FormToggleSwitch>
          <Spacing top={1}>
            <Alert severity="info">
              Marking a music as <strong>unavailable</strong> will hide it from CoManager and thus prevent customers
              from picking it for their projects. Note that projects that are currently using this music will still be
              able to be re-rendered with this music.
            </Alert>
          </Spacing>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          <FormSubmitButton>{music ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
