import styled from '@emotion/styled';
import type { FC } from 'react';

import type { AssetTransform, BackgroundType } from '@cofenster/render-description';
import { Video } from 'remotion';
import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { AssetTransformContainer } from '../../../../../containers/AssetTransform';
import { OffthreadVideo } from '../../../../../remotion/OffthreadVideo';

// Explicitly bypass the `BufferedVideo` here as we do not want to handle
// buffering for the blurred background video.
const BlurredVideo = styled(OffthreadVideo)(() => {
  const blurSize = 20;

  return {
    position: 'absolute',
    objectFit: 'cover',
    width: `calc(100% + ${2 * blurSize}px)`,
    height: `calc(100% + ${2 * blurSize}px)`,
    top: -blurSize,
    left: -blurSize,
    filter: `blur(${blurSize}px)`,
  };
});

const BackgroundContainer = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

const BackgroundColor = styled('div')<{ background: string }>(({ background }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: background === 'Primary' ? 'var(--primary-color)' : '#000',
}));

const StyledOffthreadVideo = styled(OffthreadVideo)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

const StyledVideo = styled(Video)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

export type SceneVideoElementProps = {
  src: string;
  muted: boolean;
  background: BackgroundType | null;
  startFrom: number;
  endAt?: number;
  transform?: AssetTransform;
  width?: number | null;
  height?: number | null;
};

export const SceneVideoElement: FC<SceneVideoElementProps> = ({
  src,
  muted,
  startFrom = 0,
  endAt,
  background,
  transform,
  width,
  height,
}) => {
  const { isPreview, renderDescription, onError } = useCofensterVideo();
  const VideoComponent = isPreview ? StyledVideo : StyledOffthreadVideo;
  const needsBackground =
    Boolean(transform) || renderDescription.format.width !== width || renderDescription.format.height !== height;

  return (
    <>
      {background && needsBackground && (
        <BackgroundContainer>
          {background === 'Blurred' ? (
            <BlurredVideo startFrom={startFrom} src={src} muted={true} onError={onError} />
          ) : (
            <BackgroundColor background={background} />
          )}
        </BackgroundContainer>
      )}

      <AssetTransformContainer transform={transform} width={width} height={height}>
        <VideoComponent
          startFrom={startFrom}
          endAt={endAt}
          src={src}
          muted={muted}
          pauseWhenBuffering
          onError={onError}
        />
      </AssetTransformContainer>
    </>
  );
};
