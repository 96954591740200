import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  blur: number;
};
const Container = styled('div')<ContainerProps>(({ blur }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    filter: `blur(${blur}px)`,
  };
});

type AnimateBlurProps = UseAnimateConfig & {
  className?: string;
};

export const AnimateBlur: FC<PropsWithChildren<AnimateBlurProps>> = ({ className, children, ...animateConfig }) => {
  const blur = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-blur ${className ?? ''}`} blur={blur}>
      {children}
    </Container>
  );
};
