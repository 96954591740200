import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { type FC, Fragment } from 'react';

import type { SubtitlePosition, VideoFormat } from '@cofenster/constants';
import {
  type RenderDescription,
  type RenderDescriptionSubtitleNode,
  secondsToFrames,
  totalVideoDuration,
} from '@cofenster/render-description';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { Sequence } from '../../remotion/Sequence';

const getSafeZoneMargins = (
  socialMediaSafeZone: RenderDescription['socialMediaSafeZone'] | null | undefined,
  videoFormat: VideoFormat
) => {
  if (videoFormat !== 'Vertical') return {};

  switch (socialMediaSafeZone) {
    case 'Instagram':
      return {
        marginTop: 220,
        marginRight: 170,
        marginBottom: 420,
        marginLeft: 50,
      };
    case 'TikTok':
      return {
        marginTop: 140,
        marginRight: 180,
        marginBottom: 360,
        marginLeft: 60,
      };
    default:
      return {};
  }
};

const SubtitlesContainer = styled(FullSizeFlexContainer)<{
  position: SubtitlePosition | null | undefined;
  socialMediaSafeZone: RenderDescription['socialMediaSafeZone'] | null | undefined;
  videoFormat: VideoFormat;
}>(({ position, socialMediaSafeZone, videoFormat }) => ({
  alignItems: position === 'Top' ? 'flex-start' : position === 'Middle' ? 'center' : 'flex-end',
  justifyContent: 'center',
  margin: 30,
  marginBottom: 52,
  ...getSafeZoneMargins(socialMediaSafeZone, videoFormat),
}));

const SubtitleElement = styled('div')(() => ({
  fontFamily: `'Noto Sans', 'Noto Sans SC'`,
  fontSize: 43,
  lineHeight: '52px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 32,
  paddingRight: 32,
  color: 'white',
  textAlign: 'center',
  borderRadius: 16,
  backdropFilter: 'blur(6.5px)',
}));

const isSubtitleValid = (subtitle: RenderDescriptionSubtitleNode) =>
  subtitle.end - subtitle.start > 0 || !!subtitle.text.length;

export const Subtitles: FC = () => {
  const { renderDescription, template, fps } = useCofensterVideo();
  const { subtitles, socialMediaSafeZone, format } = renderDescription;

  if (!subtitles) return null;

  const Container = template.subtitles?.container || SubtitlesContainer;
  const Wrapper = template.subtitles?.wrapper || Fragment;

  return (
    <>
      <Global styles="@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@500&family=Noto+Sans:wght@500&display=swap');" />
      <Sequence
        name="Subtitles"
        from={0}
        durationInFrames={totalVideoDuration(fps, {
          intro: renderDescription.intro,
          scenes: renderDescription.scenes,
          outro: renderDescription.outro,
        })}
      >
        <UnstylableContainer className="subtitles">
          {subtitles.filter(isSubtitleValid).map(({ start, end, text, position }, index) => (
            <Sequence
              key={`${index}-${start}-${end}`}
              name={`subtitle-${index}`}
              from={secondsToFrames(start, fps)}
              durationInFrames={secondsToFrames(end - start, fps)}
            >
              <Container
                className="subtitles-container"
                position={position}
                socialMediaSafeZone={socialMediaSafeZone}
                videoFormat={format.name}
                data-testid={`subtitle-position-${position}`}
              >
                <Wrapper>
                  <SubtitleElement className="subtitle-element">
                    {text.split('\n').map((line, index) => (
                      <Fragment key={`${line}:${index}`}>
                        {index > 0 && <br />}
                        {line}
                      </Fragment>
                    ))}
                  </SubtitleElement>
                </Wrapper>
              </Container>
            </Sequence>
          ))}
        </UnstylableContainer>
      </Sequence>
    </>
  );
};
