import styled from '@emotion/styled';
import { type FC, Fragment } from 'react';

import { type SceneColor as SceneColorDescription, sceneColorDuration } from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../../../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../../../../containers/UnstylableContainer';
import { Sequence } from '../../../../../remotion/Sequence';

const Color = styled('div')<{ color: string }>(({ color }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: color,
}));

export type SceneColorProps = {
  from: number;
  index: number;
  sceneColor: SceneColorDescription;
};

export const SceneColor: FC<SceneColorProps> = ({ from, index, sceneColor }) => {
  const { fps, template } = useCofensterVideo();

  const Container = template.sceneColor?.container || FullSizeFlexContainer;
  const Wrapper = template.sceneColor?.wrapper || Fragment;

  return (
    <Sequence name={`SceneColor-${sceneColor.id}`} from={from} durationInFrames={sceneColorDuration(sceneColor, fps)}>
      <UnstylableContainer className={`scene-color scene-color-${index}`}>
        <Container className="scene-color-container">
          <Wrapper>
            <Color className="scene-color-element" color={sceneColor.color} />
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </Sequence>
  );
};
