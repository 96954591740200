import styled from '@emotion/styled';

export const FullSizeFlexContainer = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));
