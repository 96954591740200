import { createContext } from 'react';

import type { RenderDescription } from '@cofenster/render-description';

import type { Template } from '../../template/Template';

export type CofensterVideoContext = {
  fps: number;
  durationInFrames: number;
  template: Template;
  renderDescription: RenderDescription;
  isPreview: boolean;
  onError?: VoidFunction;
  secondsToFrames: (seconds: number) => number;
};

export const CofensterVideoContext = createContext<CofensterVideoContext | undefined>(undefined);
