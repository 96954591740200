import { hex2Rgb } from './hex2rgb';

/**
 * @deprecated
 * @link https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color#answer-56678483
 * @use hex2luminance instead
 */
export const hex2brightness = (hex: string) => {
  const { red, green, blue } = hex2Rgb(hex);
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;
  return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
};
