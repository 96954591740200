import type { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import {
  Button,
  Card,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  PageHeadline,
  Spacing,
  Typography,
  formatDateTime,
  styled,
  useSearchParams,
} from '@cofenster/web-components';

import { useProject } from '../../../api/hooks/project/useProject';
import {
  Definition,
  DefinitionContent,
  DefinitionList,
  DefinitionTerm,
} from '../../../components/display/DefinitionList';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterLink } from '../../../components/navigation/RouterLink';
import { useHasPermission } from '../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateProject } from '../../../hooks/useImpersonateProject';
import { type ProjectRouteParams, routes } from '../../../routes';
import { ErrorLayout } from '../../Error';

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'underline',
  '&:focus-visible': theme.mixins.focusRing,
}));

export const Project: FC = () => {
  const { projectId } = useParams() as ProjectRouteParams;
  const [searchParams] = useSearchParams();
  const redirectPath = decodeURIComponent(searchParams.get('to') ?? '');
  const impersonate = useImpersonateProject(projectId, redirectPath);
  const canImpersonate = useHasPermission({ has: 'UserImpersonate' });
  const { project, error } = useProject(projectId);
  const title = project?.name ?? 'Impersonate project';

  if (!redirectPath) {
    return <Navigate to={routes.home} replace />;
  }

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
        topRight: (
          <Button
            variant="destructive"
            type="button"
            onClick={impersonate}
            startIcon={<Icon type="UserSwitchIcon" size="s" />}
            disabled={!canImpersonate}
          >
            Impersonate
          </Button>
        ),
      }}
    >
      <GridContainer>
        <GridItem md={6}>
          <Card>
            <Spacing bottom={1}>
              <Headline variant="h4" component="h2">
                Where am I?
              </Headline>
            </Spacing>
            <Spacing vertical={0.5}>
              <Typography component="p" variant="l">
                You have been redirected here because you attempted to open a project you do not have access to, and you
                are a <em>staff user</em> (i.e. a cofensti with CoAdmin access).
              </Typography>
            </Spacing>
            <Spacing vertical={0.5}>
              {canImpersonate ? (
                <Typography component="p" variant="l">
                  You can find basic information about said project here, or impersonate the project creator to
                  temporarily have full access to the project.
                </Typography>
              ) : (
                <Typography component="p" variant="l">
                  You can find basic information about said project here, but <strong>cannot</strong> impersonate the
                  project creator to temporarily have full access to the project, as you do not have sufficient
                  permissions.
                </Typography>
              )}
            </Spacing>
            <Spacing vertical={0.5}>
              <Typography component="p" variant="l">
                If you stumbled here by mistake, you can safely close this page.
              </Typography>
            </Spacing>
          </Card>
        </GridItem>
        <GridItem md={6}>
          <Card>
            <Spacing bottom={1}>
              <Headline variant="h4" component="h2">
                About the project
              </Headline>
            </Spacing>

            {project ? (
              <DefinitionList>
                <Definition>
                  <DefinitionTerm>ID</DefinitionTerm>
                  <DefinitionContent>{project.id}</DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Name</DefinitionTerm>
                  <DefinitionContent>{project.name}</DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Format</DefinitionTerm>
                  <DefinitionContent>{project.videoFormat}</DefinitionContent>
                </Definition>

                <Definition>
                  <DefinitionTerm>Account</DefinitionTerm>
                  <DefinitionContent>
                    {project.creator ? (
                      <StyledRouterLink to="account" params={{ accountId: project.creator.account.id }}>
                        {project.creator.account.name}
                      </StyledRouterLink>
                    ) : (
                      'Unknown account'
                    )}
                  </DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Creator</DefinitionTerm>
                  <DefinitionContent>
                    {project.creator ? (
                      <StyledRouterLink to="accountUsers" params={{ accountId: project.creator.account.id }}>
                        {project.creator.name}
                      </StyledRouterLink>
                    ) : (
                      'Unknown creator'
                    )}
                  </DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Template</DefinitionTerm>
                  <DefinitionContent>
                    <StyledRouterLink to="template" params={{ renderTemplateId: project.renderTemplate.id }}>
                      {project.renderTemplate.name}
                    </StyledRouterLink>
                  </DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Created at</DefinitionTerm>
                  <DefinitionContent>{formatDateTime(project.createdAt)}</DefinitionContent>
                </Definition>

                <Definition data-half>
                  <DefinitionTerm>Updated at</DefinitionTerm>
                  <DefinitionContent>{project.updatedAt ? formatDateTime(project.updatedAt) : 'n/a'}</DefinitionContent>
                </Definition>

                {project.deletedAt ? (
                  <Definition data-half>
                    <DefinitionTerm>Deleted at</DefinitionTerm>
                    <DefinitionContent>{formatDateTime(project.deletedAt)}</DefinitionContent>
                  </Definition>
                ) : null}
              </DefinitionList>
            ) : (
              <Typography component="p" variant="l">
                It turns out that this project did not match any project, or that it was not possible to fetch its
                information. Please contact a member of the Engineering team.
              </Typography>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </AdminLayout>
  );
};
