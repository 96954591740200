import { type FC, type ReactEventHandler, useCallback, useEffect, useState } from 'react';

import { AWS_AUDIO_KEY_NOT_APPLICABLE } from '@cofenster/constants';
import { loudnessToDuckingFactor } from '@cofenster/render-template-engine';
import { Button, Card, Headline, NativeVideoPlayer, Spacing } from '@cofenster/web-components';

const EMPTY_ARRAY: number[] = [];

const Line: FC<{ data: number[]; color?: string }> = ({ data, color = '#000' }) => {
  return (
    <polyline
      points={data.map((value, index, { length }) => `${(index / (length - 1)) * 100},${value * 100}`).join(' ')}
      fill={'none'}
      stroke={color}
      strokeWidth={1}
    />
  );
};

const Points: FC<{ data: number[]; color?: string }> = ({ data, color = '#000' }) => {
  return (
    <>
      {data.map((value, index, { length }) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: It's fine to use the index as key here
        <circle key={index} cx={(index / (length - 1)) * 100} cy={value * 100} r={0.1} fill={color} />
      ))}
    </>
  );
};

const useAudioDuckingData = (loudnessUrl?: string | null) => {
  const [data, setData] = useState<ReturnType<typeof loudnessToDuckingFactor> | null>(null);
  useEffect(() => {
    if (!loudnessUrl) return;
    if (loudnessUrl === AWS_AUDIO_KEY_NOT_APPLICABLE) return;
    const abortController = new AbortController();
    setData(null);
    fetch(loudnessUrl, { signal: abortController.signal })
      .then((res) => res.json())
      .then((data) => loudnessToDuckingFactor(data))
      .then((value) => {
        if (!abortController.signal.aborted) setData(value);
      })
      .catch((error) => {
        console.error('Error fetching audio ducking data', error);
        if (!abortController.signal.aborted) setData(null);
      });
  }, [loudnessUrl]);

  return data;
};

const AudioDuckingChart: FC<{ audioLoudnessUrl?: string | null; playbackProgress?: number }> = ({
  audioLoudnessUrl,
  playbackProgress,
}) => {
  const data = useAudioDuckingData(audioLoudnessUrl);
  const linear = data?.linear ?? EMPTY_ARRAY;
  const denoised = data?.denoised ?? EMPTY_ARRAY;
  const factors = data?.factors ?? EMPTY_ARRAY;

  return (
    <svg width="100%" height="500px" preserveAspectRatio="none" viewBox="0 -100 100 100">
      <style>{'* { vector-effect: non-scaling-stroke; }'}</style>
      <g transform="matrix(1, 0, 0, -1, 0, 0)">
        <rect x={-100} y={-100} width={200} height={200} fill={'#999'} />
        <line x1={-100} y1={0} x2={100} y2={0} stroke={'#000'} strokeWidth={2} />
        <line x1={0} y1={-100} x2={0} y2={100} stroke={'#000'} strokeWidth={2} />
        {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((value) => (
          <line
            key={value}
            x1={-100}
            y1={value}
            x2={100}
            y2={value}
            stroke={'#000'}
            strokeDasharray={1}
            strokeDashoffset={1}
            strokeWidth={1}
          />
        ))}
        <Points data={linear} />
        <Line data={denoised} color="green" />
        <Line data={factors} color="yellow" />
        {playbackProgress !== undefined && (
          <line
            x1={playbackProgress * 100}
            y1={-100}
            x2={playbackProgress * 100}
            y2={100}
            stroke={'#FFF'}
            strokeWidth={3}
            strokeDasharray={1}
            strokeDashoffset={1}
          />
        )}
      </g>
    </svg>
  );
};

export const InspectAssetPreview: FC<{ src: string; audioLoudnessUrl?: string | null }> = ({
  src,
  audioLoudnessUrl,
}) => {
  const [position, setPosition] = useState<number>(0);
  const [showChart, setShowChart] = useState(false);
  const onTimeUpdate = useCallback<ReactEventHandler<HTMLMediaElement>>(
    (event) => setPosition(event.currentTarget.currentTime / event.currentTarget.duration),
    []
  );

  return (
    <Card>
      <Spacing bottom={2}>
        <Headline component="h2" variant="h4">
          Preview
        </Headline>
      </Spacing>

      <NativeVideoPlayer src={src} actions={['DOWNLOAD', 'PLAYBACK_RATE']} onTimeUpdate={onTimeUpdate} />
      {audioLoudnessUrl && audioLoudnessUrl !== AWS_AUDIO_KEY_NOT_APPLICABLE ? (
        <Spacing top={2}>
          {showChart ? (
            <AudioDuckingChart audioLoudnessUrl={audioLoudnessUrl} playbackProgress={position} />
          ) : (
            <Button onClick={() => setShowChart(true)}>Inspect audio data</Button>
          )}
        </Spacing>
      ) : (
        'No loudness data available'
      )}
    </Card>
  );
};
