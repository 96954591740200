import { type FC, Fragment } from 'react';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { FullSizeFlexContainer } from '../../containers/FullSizeFlexContainer';
import { UnstylableContainer } from '../../containers/UnstylableContainer';
import { SceneVideoElement } from '../Scenes/Scene/SceneAssets/SceneVideo/SceneVideoElement';

export const Intro: FC = () => {
  const { template, renderDescription } = useCofensterVideo();
  const { intro } = renderDescription;

  if (!intro) return null;

  const Container = template.intro?.container || FullSizeFlexContainer;
  const Wrapper = template.intro?.wrapper || Fragment;

  return (
    <UnstylableContainer className="intro">
      <Container className="intro-container">
        <Wrapper>
          <SceneVideoElement src={intro.srcUrl} background={renderDescription.background} muted={false} startFrom={0} />
        </Wrapper>
      </Container>
    </UnstylableContainer>
  );
};
