import rgbHex from 'rgb-hex';

import { hex2luminance } from './hex2luminance';
import { hex2Rgb } from './hex2rgb';

export const createColorVariables = (hex: string, name: string) => {
  const { red, green, blue } = hex2Rgb(hex);
  const luminance = hex2luminance(hex);
  const isBright = luminance > 0.65;

  return {
    [`--${name}`]: hex,
    [`--${name}-r`]: red,
    [`--${name}-g`]: green,
    [`--${name}-b`]: blue,
    [`--${name}-luminance`]: +luminance.toFixed(2),
    [`--${name}-inverse`]: `#${rgbHex(255 - red, 255 - green, 255 - blue)}`,
    [`--${name}-high-contrast`]: isBright ? '#000000' : '#ffffff',
    [`--${name}-high-contrast-r`]: isBright ? 0 : 255,
    [`--${name}-high-contrast-g`]: isBright ? 0 : 255,
    [`--${name}-high-contrast-b`]: isBright ? 0 : 255,
  };
};
