import type { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  Icon,
  IconButton,
  Typography,
  UserProfile,
  UserStatus,
  formatDateTime,
} from '@cofenster/web-components';

import type { User } from '../../../../../api/hooks/account/useAccount';
import { ExpandedRouterLink } from '../../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction } from '../../../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateUser } from '../../../../../hooks/useImpersonateUser';

const HeaderCell: FC<{ column: ColumnConfig<User> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const WrappedUserProfile: FC<{ item: User }> = ({ item }) => {
  const { accountId } = useParams();

  const Component: FC<PropsWithChildren> = ({ children }) => (
    <PermissionRestriction
      has="UserUpdate"
      fallback={
        <span data-testid="admin-user-list-item" onDoubleClick={() => navigator.clipboard.writeText(item.id)}>
          {children}
        </span>
      }
    >
      <ExpandedRouterLink to="user" params={{ userId: item.id, accountId }} data-testid="admin-user-list-item">
        {children}
      </ExpandedRouterLink>
    </PermissionRestriction>
  );

  return <UserProfile user={item} component={Component} />;
};

const ImpersonateButton: FC<{ item: User }> = ({ item }) => {
  const impersonate = useImpersonateUser(item.id);
  const deactivatedUser = Boolean(item.deactivatedAt);

  if (deactivatedUser) return null;

  return (
    <PermissionRestriction has="UserImpersonate">
      <IconButton label={`Impersonate ${item.name}`} onClick={impersonate} icon="UserSwitchIcon" iconWeight="fill" />
    </PermissionRestriction>
  );
};

const columns: CSSGridTableConfig<User>['columns'] = [
  {
    id: 'nameAndEmail',
    name: 'Members',
    cell: ({ item }) => <WrappedUserProfile item={item} />,
    header: HeaderCell,
  },
  {
    id: 'impersonate',
    name: '',
    cell: ({ item }) => <ImpersonateButton item={item} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'activity',
    name: 'Last signin',
    cell: ({ item }) => (
      <Typography variant="m" component={item.lastSignInAt ? 'time' : 'span'}>
        {item.lastSignInAt ? formatDateTime(item.lastSignInAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'paidSeat',
    name: 'Seat',
    cell: ({ item }) =>
      item.roles.account.paid || item.roles.team.some((t) => t.role.paid) ? <Icon type="OfficeChairIcon" /> : null,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'userRole',
    name: 'Role',
    cell: ({ item }) => item.roles.account.name,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'userStatus',
    name: 'Status',
    cell: ({ item }) => (
      <UserStatus createdAt={item.createdAt} deactivatedAt={item.deactivatedAt} isPending={item.isPending} />
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const AccountUserList: FC<{ users: User[] }> = ({ users }) => {
  if (users.length === 0) {
    return (
      <EmptyState
        iconType="NoOptionIcon"
        title="No users"
        description="There are currently no users in this account."
      />
    );
  }

  return <CSSGridTable data={users} columns={columns} data-testid="members-list" />;
};
