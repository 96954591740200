import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = Pick<AnimateScaleProps, 'transformOrigin'> & {
  scale: number;
};
const Container = styled('div')<ContainerProps>(({ scale, transformOrigin }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    transform: `scale(${scale})`,
    transformOrigin,
  };
});

type AnimateScaleProps = UseAnimateConfig & {
  className?: string;
  transformOrigin?: string;
};

export const AnimateScale: FC<PropsWithChildren<AnimateScaleProps>> = ({
  className,
  children,
  transformOrigin = 'center',
  ...animateConfig
}) => {
  const scale = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-scale ${className ?? ''}`} transformOrigin={transformOrigin} scale={scale}>
      {children}
    </Container>
  );
};
