import type { AssetTransform, BackgroundType } from '@cofenster/render-description';
import styled from '@emotion/styled';
import type { FC } from 'react';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { AssetTransformContainer } from '../../../../../containers/AssetTransform';
import { Image } from '../../../../../remotion/Image';

const StyledImage = styled(Image)(() => ({
  display: 'block',
  position: 'absolute',
  objectFit: 'contain',
  width: '100%',
  height: '100%',
}));

const BackgroundContainer = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

const BackgroundColor = styled('div')<{ background: string }>(({ background }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: background === 'Primary' ? 'var(--primary-color)' : '#000',
}));

const BlurredImage = styled(Image)(() => {
  const blurSize = 20;
  return {
    position: 'absolute',
    display: 'block',
    objectFit: 'cover',
    width: `calc(100% + ${2 * blurSize}px)`,
    height: `calc(100% + ${2 * blurSize}px)`,
    top: -blurSize,
    left: -blurSize,
    filter: `blur(${blurSize}px)`,
  };
});

export type SceneImageElementProps = {
  src: string;
  background: BackgroundType | null;
  transform?: AssetTransform;
  width?: number | null;
  height?: number | null;
};

export const SceneImageElement: FC<SceneImageElementProps> = ({ src, background, transform, width, height }) => {
  const { renderDescription } = useCofensterVideo();
  const needsBackground =
    Boolean(transform) || renderDescription.format.width !== width || renderDescription.format.height !== height;

  return (
    <>
      {background && needsBackground && (
        <BackgroundContainer>
          {background === 'Blurred' ? <BlurredImage src={src} /> : <BackgroundColor background={background} />}
        </BackgroundContainer>
      )}

      <AssetTransformContainer width={width} height={height} transform={transform}>
        <StyledImage src={src} pauseWhenLoading />
      </AssetTransformContainer>
    </>
  );
};
