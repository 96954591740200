import { LAYER_INDEXES } from '../../../constants/src';
import type { Scene } from '../types/RenderDescription';

import { sceneAssetDuration } from './sceneAssetDuration';

export const sceneDuration = (scene: Scene, fps: number, ignoreSections = false) => {
  const sceneAssetDurations = scene.sceneAssets
    // Only consider main assets (layer 1) for the duration of a scene
    .filter((sceneAsset) => sceneAsset.layerIndex === LAYER_INDEXES.main)
    .map((sceneAsset) => {
      return sceneAssetDuration(sceneAsset, fps, ignoreSections);
    });
  return Math.max(...sceneAssetDurations);
};
