import type { RenderDescription } from '@cofenster/render-description';
import { useMemo } from 'react';

export const useUniqueUrls = (renderDescription: RenderDescription) => {
  const { intro, outro, scenes } = renderDescription;

  return useMemo(() => {
    const urls = new Set<string>();

    if (intro?.srcUrl) urls.add(intro.srcUrl);

    scenes
      .flatMap((scene) => scene.sceneAssets)
      .forEach((sceneAsset) => {
        if ('srcUrl' in sceneAsset) urls.add(sceneAsset.srcUrl);
        if ('audioLoudnessUrl' in sceneAsset && sceneAsset.audioLoudnessUrl) urls.add(sceneAsset.audioLoudnessUrl);
      });

    if (outro?.srcUrl) urls.add(outro.srcUrl);

    return Array.from(urls);
  }, [intro, outro, scenes]);
};
