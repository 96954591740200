import { useEffect, useState } from 'react';

export const useAbortOnUnmount = () => {
  const [abortController] = useState(() => new AbortController());

  useEffect(() => {
    return () => abortController.abort('Unmounted');
  }, [abortController]);

  return abortController.signal;
};
