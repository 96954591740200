import { type FC, useMemo } from 'react';
import { Series, useCurrentFrame } from 'remotion';

import {
  type RenderDescription,
  type SceneVideo,
  sceneVideoSectionDuration,
  sceneVideoSectionStart,
} from '@cofenster/render-description';

import { useCofensterVideo } from '../../../../../../context/cofensterVideo';
import { Sequence } from '../../../../../remotion/Sequence';

import { SceneVideoSection } from './SceneVideoSection';

export type TimelineItem = {
  durationInFrames: number;
  startFrameInOriginalVideo: number;
  startFrameInSequence: number;
};

const sum = (a: number, b: number) => a + b;

const useSectionTimeline = (sections: SceneVideo['sections'], fps: number): TimelineItem[] => {
  return useMemo(
    () =>
      sections
        ?.map((section) => ({
          durationInFrames: sceneVideoSectionDuration(section, fps),
          startFrameInOriginalVideo: sceneVideoSectionStart(section, fps),
        }))
        .filter((timing) => timing.durationInFrames >= 1)
        .sort((a, b) => a.startFrameInOriginalVideo - b.startFrameInOriginalVideo)
        // For each section, compute the sequence frame at which it should start
        // by accumulating the duration in frames of all previous sections.
        .map((timing, index, timings) => ({
          ...timing,
          startFrameInSequence: timings
            .slice(0, index)
            .map((timing) => timing.durationInFrames)
            .reduce(sum, 0),
        })) ?? [],
    [sections, fps]
  );
};

export const SceneVideoSections: FC<{
  sceneVideo: SceneVideo;
  background: RenderDescription['background'];
}> = ({ sceneVideo, background }) => {
  const { fps, isPreview } = useCofensterVideo();
  const { sections } = sceneVideo;
  const timeline = useSectionTimeline(sections, fps);
  const frame = useCurrentFrame();
  const currSection = useMemo(
    () =>
      timeline
        .slice()
        .reverse()
        .find((section) => section.startFrameInSequence <= frame),
    [frame, timeline]
  );

  if (!currSection) {
    return null;
  }

  // Technically, the `Series` approach works just fine for the preview as well.
  // However, having one `Sequence` per section causes the video element (i.e.
  // `SceneVideoSection`) to be mounted and unmounted at every section change.
  // This causes a micro-flash while that removal/addition to the DOM happens.
  // To work around the problem, we render a single video element for all
  // sections, but we dynamically change its `startFrom` property so it moves to
  // the right time based on the current section. This avoids doing any mounting
  // and unmounting, which removes the flickering problem.
  // See: https://github.com/remotion-dev/video-with-jump-cuts/tree/main
  if (isPreview) {
    return (
      <Sequence from={currSection.startFrameInSequence}>
        <SceneVideoSection
          sceneVideo={sceneVideo}
          background={background}
          // We overlap sections by 1 frame so we don’t have any flicker caused
          // by empty frames or half-frames
          startFrom={Math.max(0, currSection.startFrameInOriginalVideo - 1)}
          endAt={currSection.startFrameInOriginalVideo + currSection.durationInFrames}
        />
      </Sequence>
    );
  }

  return (
    <Series>
      {timeline.map(({ startFrameInOriginalVideo, durationInFrames }) => (
        <Series.Sequence
          key={startFrameInOriginalVideo}
          name={`SceneVideoSection-${startFrameInOriginalVideo}`}
          durationInFrames={durationInFrames}
          layout="none"
        >
          <SceneVideoSection sceneVideo={sceneVideo} background={background} startFrom={startFrameInOriginalVideo} />
        </Series.Sequence>
      ))}
    </Series>
  );
};
