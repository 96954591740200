import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = Pick<AnimateRotateProps, 'transformOrigin'> & {
  rotate: number;
};
const Container = styled('div')<ContainerProps>(({ rotate, transformOrigin }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    transform: `rotate(${rotate}deg)`,
    transformOrigin,
  };
});

type AnimateRotateProps = UseAnimateConfig & {
  className?: string;
  transformOrigin?: string;
};

export const AnimateRotate: FC<PropsWithChildren<AnimateRotateProps>> = ({
  className,
  children,
  transformOrigin = 'center',
  ...animateConfig
}) => {
  const rotate = useAnimate(animateConfig);
  return (
    <Container
      className={`animate animate-rotate ${className ?? ''}`}
      transformOrigin={transformOrigin}
      rotate={rotate}
    >
      {children}
    </Container>
  );
};
