import { type FC, useCallback } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  GridContainer,
  GridItem,
  Icon,
  ResultPagination,
  Typography,
  formatDateTime,
  styled,
  useGoto,
} from '@cofenster/web-components';

import type {
  Filter,
  PaginatedTemplates,
  RenderTemplate,
} from '../../../../api/hooks/renderTemplate/useRenderTemplates';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../../routes';

const HeaderCell: FC<{ column: ColumnConfig<RenderTemplate> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const StyledTypography = styled(Typography)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '1ch',
}));

const columns: CSSGridTableConfig<RenderTemplate>['columns'] = [
  {
    id: 'name_EN',
    name: 'Name',
    cell: ({ item }) => (
      <PermissionRestriction
        has="TemplateUpdate"
        fallback={
          <StyledTypography variant="h6" color="carbon">
            <Icon type={item.templateIdentifier === 'json-template' ? 'AppWindowIcon' : 'CodeIcon'} size="ms" />
            {item.nameEN}
          </StyledTypography>
        }
      >
        <ExpandedRouterLink to="template" params={{ renderTemplateId: item.id }}>
          <StyledTypography variant="h6" color="carbon">
            <Icon type={item.templateIdentifier === 'json-template' ? 'AppWindowIcon' : 'CodeIcon'} size="ms" />
            {item.nameEN}
          </StyledTypography>
        </ExpandedRouterLink>
      </PermissionRestriction>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {formatDateTime(item.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'updatedAt',
    name: 'Updated at',
    cell: ({ item }) => (
      <Typography variant="m" component={item.updatedAt ? 'time' : 'span'}>
        {item.updatedAt ? formatDateTime(item.updatedAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'usage',
    name: 'Used by',
    cell: ({ item }) => (
      <Typography variant="m" component="p">
        {item.projectCount === 1 ? '1 project' : `${item.projectCount} projects`}
        {' · '}
        {item.accountsUsageCount === 1 ? '1 account' : `${item.accountsUsageCount} accounts`}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'assignment',
    name: 'Assigned to',
    cell: ({ item }) => (
      <Typography variant="m" component="p">
        {item.accountsAssignedCount === 1 ? '1 account' : `${item.accountsAssignedCount} accounts`}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const TemplatesList: FC<{
  page: number;
  setPage: (page: number) => void;
  search: Filter['search'];
  templates?: PaginatedTemplates;
}> = ({ page, setPage, search, templates: paginatedTemplates }) => {
  const goto = useGoto();
  const handleRowClick = useCallback(
    (item: RenderTemplate) => item && goto(routes.template, { renderTemplateId: item.id }),
    [goto]
  );
  const canUpdateTemplates = useHasPermission({ has: 'TemplateUpdate' });

  if (!paginatedTemplates || paginatedTemplates.items.length === 0) {
    return (
      <EmptyState
        iconType="SearchIcon"
        title="No templates found"
        description={search ? <>The search for “{search}” returned no results.</> : <>No templates found.</>}
      />
    );
  }

  return (
    <>
      <CSSGridTable
        data={paginatedTemplates.items}
        columns={columns}
        onRowClick={canUpdateTemplates ? handleRowClick : undefined}
      />
      <GridContainer justifyContent="center" pt={3}>
        <GridItem>
          <ResultPagination
            total={paginatedTemplates.total}
            limit={paginatedTemplates.limit ?? paginatedTemplates.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
