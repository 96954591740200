import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { useCofensterVideo } from '../../context/cofensterVideo/useCofensterVideo';
import type { SVGDefinition, SVGElementStyleProperties, SVGPathStyleProperties } from './types';
import { useStyleDeclaration } from './useStyleDeclaration';

const isPathDInCssSupported = () => {
  return CSS.supports('d', "path('')");
};

const extractPathDFromCss = (value?: string) => /path\(["'](.+)["']\)/.exec(value ?? '')?.[1] ?? undefined;

const StyleAwareSVGElement: FC<
  PropsWithChildren<{
    definition?: SVGElementStyleProperties;
  }>
> = ({ children, definition = {} }) => {
  const {
    renderDescription: {
      format: { width, height },
    },
  } = useCofensterVideo();
  const declaration = useStyleDeclaration(definition);
  const Element = styled('svg')({
    position: 'absolute',
    ...declaration,
  });
  return (
    <Element viewBox={definition?.viewBox ?? `0 0 ${width} ${height}`} height="100%" width="100%">
      {children}
    </Element>
  );
};

const StyleAwareSVGPath = styled('path', {
  shouldForwardProp: (prop) => !(['definition'] as PropertyKey[]).includes(prop),
})<{ definition: SVGPathStyleProperties }>(({ definition }) => useStyleDeclaration(definition));

export const AdditionalSVGContent: FC<{ definition?: SVGDefinition }> = ({ definition }) => {
  if (!definition) return null;

  const svgPaths = Object.entries(definition)
    .filter(([key]) => key.startsWith('path_'))
    .map(([key, value]) => {
      return (
        <StyleAwareSVGPath
          key={key}
          definition={value}
          d={isPathDInCssSupported() ? undefined : extractPathDFromCss(value.css?.d)}
        />
      );
    });

  return <StyleAwareSVGElement definition={definition.element}>{svgPaths}</StyleAwareSVGElement>;
};
