import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  blur: number;
};

const BlurContainer = styled('div')<ContainerProps>(({ blur }) => {
  const blurWhite = blur / 20;

  return {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    position: 'relative',
    backdropFilter: `blur(${blur}px)`,
    boxShadow: `inset 2000px 0 0 0 rgba(255, 255, 255, ${blurWhite})`,
  };
});

type MainProps = UseAnimateConfig & {
  className?: string;
};

export const AnimateBackgroundBlur: FC<PropsWithChildren<MainProps>> = ({ className, children, ...animateConfig }) => {
  const blur = useAnimate(animateConfig);
  return (
    <BlurContainer className={`animate animate-blur ${className ?? ''}`} blur={blur}>
      {children}
    </BlurContainer>
  );
};
