import { type FC, Fragment, useEffect } from 'react';

import type { RenderDescription } from '@cofenster/render-description';
import { FullSizeFlexContainer } from './components/containers/FullSizeFlexContainer';
import { UnstylableContainer } from './components/containers/UnstylableContainer';
import { BackgroundMusic } from './components/videoElements/BackgroundMusic';
import { Colors } from './components/videoElements/Colors';
import { Fonts } from './components/videoElements/Fonts';
import { Logo } from './components/videoElements/Logo';
import { ScenesWithIntroOutro } from './components/videoElements/ScenesWithIntroOutro';
import { Sizes } from './components/videoElements/Sizes';
import { Subtitles } from './components/videoElements/Subtitles';
import { Transitions } from './components/videoElements/Transitions';
import { CofensterVideoProvider } from './context/cofensterVideo';
import type { Template } from './template/Template';

export type CofensterVideoProps = {
  template: Template;
  renderDescription?: RenderDescription;
  isPreview?: boolean;
  onPlayerReady?: VoidFunction;
  onError?: VoidFunction;
};

export const CofensterVideo: FC<CofensterVideoProps> = ({
  template,
  renderDescription,
  isPreview = false,
  onPlayerReady,
  onError,
}) => {
  if (!renderDescription) {
    throw new Error('Missing renderDescription');
  }

  const handleBgMusic = !!renderDescription.backgroundMusic?.remotion;

  const Container = template.cofensterVideo?.container || FullSizeFlexContainer;

  const Wrapper = template.cofensterVideo?.wrapper || Fragment;

  // It is important to call `onPlayerReady` in *this* component and not in
  // `createPreview` even though it pertains to the preview mode because we need
  // to guarantee the player ref (from the top-level component) has been
  // assigned and is available.
  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to fire it once on mount
  useEffect(() => {
    onPlayerReady?.();
  }, []);

  return (
    <CofensterVideoProvider
      template={template}
      renderDescription={renderDescription}
      isPreview={isPreview}
      onError={onError}
    >
      <UnstylableContainer className={`cofenster-video format-${renderDescription.format.name.toLowerCase()}`}>
        <Container className="cofenster-video-container">
          <Wrapper>
            {handleBgMusic && <BackgroundMusic />}

            <Sizes />
            <Colors />
            <Fonts />

            <ScenesWithIntroOutro />

            <Logo />
            <Subtitles />
            <Transitions />
          </Wrapper>
        </Container>
      </UnstylableContainer>
    </CofensterVideoProvider>
  );
};
