import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';
import type { DimensionUnit } from '../types';

type ContainerProps = NonNullable<Pick<AnimateHeightProps, 'unit'>> & {
  height: number;
};
const Container = styled('div')<ContainerProps>(({ height, unit }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    maxHeight: `${height}${unit}`,
  };
});

export type AnimateHeightProps = UseAnimateConfig & {
  className?: string;
  unit?: DimensionUnit;
};

export const AnimateHeight: FC<PropsWithChildren<AnimateHeightProps>> = ({
  className,
  children,
  unit = '%',
  ...animateConfig
}) => {
  const height = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-height ${className ?? ''}`} unit={unit} height={height}>
      {children}
    </Container>
  );
};
