import { type FC, useCallback } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  Chip,
  type ColumnConfig,
  LoadingSpinner,
  PageHeadline,
  Typography,
  formatDateTime,
  useGoto,
} from '@cofenster/web-components';

import { type StaffUser, useStaffUsers } from '../../../api/hooks/staffUser/useStaffUsers';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { ExpandedRouterLink } from '../../../components/navigation/ExpandedRouterLink';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { PermissionRestriction, useHasPermission } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

const HeaderCell: FC<{ column: ColumnConfig<StaffUser> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<StaffUser>['columns'] = [
  {
    id: 'staffUserName',
    name: 'Name',
    cell: ({ item: staffUser }) => (
      <PermissionRestriction
        has="StaffUserUpdate"
        fallback={
          <Typography variant="h6" color="carbon">
            {staffUser.name}
          </Typography>
        }
      >
        <ExpandedRouterLink to="staffUser" params={{ staffUserId: staffUser.id }} data-testid="staff-user-link">
          <Typography variant="h6" color="carbon">
            {staffUser.name}
          </Typography>
        </ExpandedRouterLink>
      </PermissionRestriction>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },

  {
    id: 'email',
    name: 'Email',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {item.email}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },

  { id: 'space', extra: { size: 'minmax(0, auto)' } },

  {
    id: 'role',
    name: 'Role',
    cell: ({ item }) => (
      <Chip color={item.role?.name === 'Administrator' ? 'dark' : 'light'}>
        {item.role?.name.replace(/([A-Z])/g, ' $1') ?? 'n/a'}
      </Chip>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'lastSignInAt',
    name: 'Last login',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {item.lastSignInAt ? formatDateTime(item.lastSignInAt) : 'Never'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const StaffUsersList: FC = () => {
  const goto = useGoto();
  const { staffUsers, loading, error } = useStaffUsers();
  const title = 'Staff users';

  const handleRowClick = useCallback((item: StaffUser) => goto(routes.staffUser, { staffUserId: item.id }), [goto]);
  const canUpdateStaffUsers = useHasPermission({ has: 'StaffUserUpdate' });

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="StaffUserRead" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} />,
          topRight: (
            <PermissionRestriction has="StaffUserCreate">
              <RouterButton to="staffUserCreate" data-testid="add-staff-user-button">
                Add staff user
              </RouterButton>
            </PermissionRestriction>
          ),
        }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <CSSGridTable
            data={staffUsers}
            columns={columns}
            onRowClick={canUpdateStaffUsers ? handleRowClick : undefined}
          />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
