import {
  type CallbackListener,
  Player,
  type EventTypes as PlayerEventTypes,
  type PlayerPropsWithoutZod,
  type PlayerRef,
  type RenderPoster,
} from '@remotion/player';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { type RenderDescription, totalVideoDuration } from '@cofenster/render-description';

import { CofensterVideo, type CofensterVideoProps } from '../CofensterVideo';
import { LoadingSpinner } from '../components/videoElements/LoadingSpinner';
import { useAssetPreloading } from '../context/assetPreloading/useAssetPreloading';
import { useBuffering } from '../hooks/useBuffering';
import type { Template } from '../template/Template';

interface RemotionPreviewRef {
  play(): void;
  pause(): void;
  addEventListener<Q extends PlayerEventTypes>(name: Q, callback: CallbackListener<Q>): void;
  removeEventListener<Q extends PlayerEventTypes>(name: Q, callback: CallbackListener<Q>): void;
  currentTime: number;
  readonly duration: number;
  readonly paused: boolean;
}

type PickOptional<T> = { [K in keyof T as NonNullable<unknown> extends Pick<T, K> ? K : never]: T[K] };

export type RemotionPlayerProps = PickOptional<PlayerPropsWithoutZod<CofensterVideoProps>>;
export type RemotionPreviewProps = RemotionPlayerProps & {
  renderDescription: RenderDescription;
  onEnd?: VoidFunction;
  onError?: VoidFunction;
  onLoadingStateChanged?: (loading: boolean) => void;
  onPause?: VoidFunction;
  onPlay?: VoidFunction;
  onPlayerReady?: VoidFunction;
  onProgress?: (p: { currentFrame: number; totalFrames: number; currentTime: number; totalTime: number }) => void;
  onSeeked?: (p: { currentFrame: number; totalFrames: number; currentTime: number; totalTime: number }) => void;
};

const PreviewPlayer = forwardRef<RemotionPreviewRef, RemotionPreviewProps & { template: Template }>(
  function PreviewPlayer(
    {
      renderDescription,
      template,
      onPlay,
      onPause,
      onEnd,
      onSeeked,
      onProgress,
      onError,
      style,
      allowFullscreen = false,
      autoPlay = false,
      clickToPlay = false,
      controls = false,
      doubleClickToFullscreen = false,
      loop = false,
      showVolumeControls = false,
      spaceKeyToPlayOrPause = false,
      onLoadingStateChanged,
      moveToBeginningWhenEnded = true,
      onPlayerReady,
      errorFallback,
    },
    ref
  ) {
    const playerRef = useRef<PlayerRef>(null);
    const fps = renderDescription.format.fps;
    const totalFrames = totalVideoDuration(fps, {
      intro: renderDescription.intro,
      scenes: renderDescription.scenes,
      outro: renderDescription.outro,
    });

    useAssetPreloading(renderDescription);

    useImperativeHandle(
      ref,
      () => {
        return {
          play() {
            playerRef.current?.play();
          },
          pause() {
            playerRef.current?.pause();
          },
          addEventListener<Q extends PlayerEventTypes>(name: Q, callback: CallbackListener<Q>) {
            playerRef.current?.addEventListener(name, callback);
          },
          removeEventListener<Q extends PlayerEventTypes>(name: Q, callback: CallbackListener<Q>) {
            playerRef.current?.removeEventListener(name, callback);
          },
          get currentTime() {
            return (playerRef.current?.getCurrentFrame() ?? 0) / fps;
          },
          set currentTime(value: number) {
            playerRef.current?.seekTo(value * fps);
          },
          get duration() {
            return totalFrames / fps;
          },
          get paused() {
            return !(playerRef.current?.isPlaying() ?? false);
          },
        };
      },
      [fps, totalFrames]
    );

    const handleSeeked: CallbackListener<'seeked'> = useCallback(
      (data) =>
        onSeeked?.({
          currentFrame: data.detail.frame,
          totalFrames,
          currentTime: data.detail.frame / fps,
          totalTime: totalFrames / fps,
        }),
      [onSeeked, totalFrames, fps]
    );

    const handleProgress: CallbackListener<'timeupdate'> = useCallback(
      (data) => {
        onProgress?.({
          currentFrame: data.detail.frame,
          totalFrames,
          currentTime: data.detail.frame / fps,
          totalTime: totalFrames / fps,
        });
      },
      [onProgress, totalFrames, fps]
    );

    useEffect(() => {
      const player = playerRef.current;

      if (player) {
        if (onPlay) player.addEventListener('play', onPlay);
        if (onPause) player.addEventListener('pause', onPause);
        if (onEnd) player.addEventListener('ended', onEnd);
        if (onError) player.addEventListener('error', onError);
        if (onSeeked) player.addEventListener('seeked', handleSeeked);
        if (onProgress) player.addEventListener('timeupdate', handleProgress);
      }

      return () => {
        if (player) {
          if (onPlay) player.removeEventListener('play', onPlay);
          if (onPause) player.removeEventListener('pause', onPause);
          if (onEnd) player.removeEventListener('ended', onEnd);
          if (onError) player.removeEventListener('error', onError);
          if (onSeeked) player.removeEventListener('seeked', handleSeeked);
          if (onProgress) player.removeEventListener('timeupdate', handleProgress);
        }
      };
    }, [onPlay, onPause, onEnd, onError, onSeeked, onProgress, handleSeeked, handleProgress]);

    const isBuffering = useBuffering(playerRef);

    useEffect(() => {
      onLoadingStateChanged?.(isBuffering);
    }, [onLoadingStateChanged, isBuffering]);

    const renderPoster: RenderPoster = useCallback(() => (isBuffering ? <LoadingSpinner /> : null), [isBuffering]);
    const inputProps: CofensterVideoProps = useMemo(
      () => ({
        template,
        renderDescription,
        isPreview: true,
        onPlayerReady,
        onError,
      }),
      [template, renderDescription, onPlayerReady, onError]
    );

    return (
      <Player
        ref={playerRef}
        // By default, Remotion bootstraps some hidden audio players to
        // circumvent an issue with Mobile Safari refusing to play audio files
        // without an explicit user interaction first. However, these audio
        // players (`WebMediaPlayer` low-level constructs):
        // - Are generated way too often for some reason. This can be attested
        //   in the Chrome or Microsoft Edge DevTools by opening the “Media”
        //   tab and switching between 2 video scenes in the editor. Hundreds
        //   of media players get generated (possibly several per React
        //   renders).
        // - Do not get flushed properly. This can also be confirmed by
        //   checking the “Media” tab: the players are “suspended” instead of
        //   “destroyed” which causes a Chromium-based browser to eventually
        //   throw an “Intervention” as too many `WebMediaPlayer` co-exist.
        // Note: it is unclear whether this problem exists in the Remotion
        // core library or is related to the way we use Remotion in this
        // project.
        // See: https://www.notion.so/cofenster/WebManager-Loading-videos-in-the-editor-fails-on-Edge-35e03f3842564e2ab6e22249e33eb95f
        // See: https://www.remotion.dev/docs/player/player#numberofsharedaudiotags-
        numberOfSharedAudioTags={0}
        component={CofensterVideo}
        durationInFrames={totalFrames}
        compositionWidth={renderDescription.format.width}
        compositionHeight={renderDescription.format.height}
        fps={fps}
        style={style}
        allowFullscreen={allowFullscreen}
        autoPlay={autoPlay}
        clickToPlay={clickToPlay}
        controls={controls}
        doubleClickToFullscreen={doubleClickToFullscreen}
        loop={loop}
        showVolumeControls={showVolumeControls}
        spaceKeyToPlayOrPause={spaceKeyToPlayOrPause}
        inputProps={inputProps}
        moveToBeginningWhenEnded={moveToBeginningWhenEnded}
        showPosterWhenBuffering
        renderPoster={renderPoster}
        errorFallback={errorFallback}
      />
    );
  }
);

export const createPreview = (template: Template) => {
  return forwardRef<RemotionPreviewRef, RemotionPreviewProps>(function TemplatePreview(props, ref) {
    return <PreviewPlayer template={template} {...props} ref={ref} />;
  });
};
