import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';

type ContainerProps = {
  inset?: string;
};
const Container = styled('div')<ContainerProps>(({ inset }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    clipPath: inset ? `inset(${inset})` : undefined,
  };
});

type RevealProps = Omit<UseAnimateConfig, 'fromValue' | 'toValue'> & {
  in?: Omit<UseAnimateConfig['in'], 'fromValue' | 'toValue'>;
  out?: Omit<UseAnimateConfig['out'], 'fromValue' | 'toValue'>;
  className?: string;
  onlyClipWhenNeeded?: boolean;
  revealFrom?:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'right'
    | 'bottom'
    | 'bottom-right'
    | 'bottom-left'
    | 'left'
    | 'center'
    | 'center-x'
    | 'center-y';
};

export const Reveal: FC<PropsWithChildren<RevealProps>> = ({
  className,
  children,
  revealFrom = 'left',
  onlyClipWhenNeeded = false,
  ...animateConfig
}) => {
  const value = useAnimate({
    toValue: 0,
    fromValue: ['center', 'center-x', 'center-y'].includes(revealFrom) ? 50 : 100,
    ...animateConfig,
  });
  let top = 0;
  let right = 0;
  let bottom = 0;
  let left = 0;

  if (revealFrom === 'center') {
    top = value;
    right = value;
    bottom = value;
    left = value;
  }
  if (revealFrom === 'center-x') {
    right = value;
    left = value;
  }
  if (revealFrom === 'center-y') {
    top = value;
    bottom = value;
  }
  if (['top', 'top-left', 'top-right'].includes(revealFrom)) bottom = value;
  if (['bottom', 'bottom-left', 'bottom-right'].includes(revealFrom)) top = value;
  if (['left', 'top-left', 'bottom-left'].includes(revealFrom)) right = value;
  if (['right', 'top-right', 'bottom-right'].includes(revealFrom)) left = value;

  const inset =
    onlyClipWhenNeeded && top === 0 && right === 0 && bottom === 0 && left === 0
      ? undefined
      : `${top}% ${right}% ${bottom}% ${left}%`;

  return (
    <Container className={`reveal reveal-${revealFrom} ${className ?? ''}`} inset={inset}>
      {children}
    </Container>
  );
};
