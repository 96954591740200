import styled from '@emotion/styled';
import type { FC } from 'react';

const LoadingSpinnerContainer = styled('div')(() => ({
  zIndex: 10000,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(0 0 0 / 0.6)',

  '> span': {
    width: 100,
    height: 100,
    border: '5px solid #FFF',
    borderBottomColor: 'rgb(64, 0, 248)',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite',
  },

  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export const LoadingSpinner: FC = () => {
  return (
    <LoadingSpinnerContainer>
      <span />
    </LoadingSpinnerContainer>
  );
};
