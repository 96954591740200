import type { FC } from 'react';

import { useCurrentFrame } from 'remotion';

export const DebugAudioDucking: FC<{
  volume: number;
  duckingVolume: (frame: number) => number;
}> = ({ volume, duckingVolume }) => {
  const frame = useCurrentFrame();
  const debugVolume = duckingVolume(frame);
  return (
    <div
      style={{
        position: 'absolute',
        top: 50,
        left: 50,
        fontSize: 30,
        color: debugVolume < volume / 100 ? 'white' : undefined,
        background: debugVolume < volume / 100 ? 'green' : 'white',
        padding: 10,
        zIndex: 1000,
      }}
    >
      {(debugVolume * 100).toFixed(0)}%
    </div>
  );
};
