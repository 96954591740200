import type { FC } from 'react';
import { Audio } from 'remotion';

import { useCofensterVideo } from '../../../context/cofensterVideo/useCofensterVideo';
import { Sequence } from '../../remotion/Sequence';
import { DebugAudioDucking } from './DebugAudioDucking';
import { useDuckingFactors } from './useDuckingFactors';
import { useDuckingVolume } from './useDuckingVolume';

export const BackgroundMusic: FC = () => {
  const { renderDescription, secondsToFrames, durationInFrames } = useCofensterVideo();

  if (!renderDescription.backgroundMusic?.srcUrl) {
    return null;
  }

  if (renderDescription.backgroundMusic.volume === 0) {
    return null;
  }

  const introDuration = secondsToFrames(renderDescription.intro?.duration ?? 0);
  const outroDuration = secondsToFrames(renderDescription.outro?.duration ?? 0);
  const musicDuration = durationInFrames - introDuration - outroDuration;

  return (
    <Sequence from={introDuration} durationInFrames={musicDuration}>
      <DuckedBackgroundMusic
        offsetIn={introDuration}
        duration={musicDuration}
        srcUrl={renderDescription.backgroundMusic.srcUrl}
        volume={renderDescription.backgroundMusic.volume}
      />
    </Sequence>
  );
};

const DuckedBackgroundMusic: FC<{ srcUrl: string; volume: number; offsetIn: number; duration: number }> = ({
  srcUrl,
  volume,
  offsetIn,
  duration,
}) => {
  const duckingFactors = useDuckingFactors(useCofensterVideo().renderDescription);
  const duckingVolume = useDuckingVolume(duckingFactors, volume / 100, duration, offsetIn);

  return (
    <>
      {__DEBUG_AUDIO_DUCKING__ && <DebugAudioDucking volume={volume} duckingVolume={duckingVolume} />}
      <Audio src={srcUrl} volume={duckingVolume} loop />
    </>
  );
};
