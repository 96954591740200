import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';

import { type UseAnimateConfig, useAnimate } from '../../../hooks/animate/useAnimate';
import type { DimensionUnit } from '../types';

type ContainerProps = NonNullable<Pick<AnimateWidthProps, 'unit'>> & {
  width: number;
};
const Container = styled('div')<ContainerProps>(({ width, unit }) => {
  return {
    display: 'inline-block',
    position: 'relative',
    maxWidth: `${width}${unit}`,
  };
});

type AnimateWidthProps = UseAnimateConfig & {
  className?: string;
  unit?: DimensionUnit;
};

export const AnimateWidth: FC<PropsWithChildren<AnimateWidthProps>> = ({
  className,
  children,
  unit = '%',
  ...animateConfig
}) => {
  const width = useAnimate(animateConfig);
  return (
    <Container className={`animate animate-width ${className ?? ''}`} unit={unit} width={width}>
      {children}
    </Container>
  );
};
