import { hex2Rgb } from './hex2rgb';

/**
 * Convert a hex color to a relative luminance value
 * Values over 0.65 are considered bright
 */
export const hex2luminance = (hex: string) => {
  const { red, green, blue } = hex2Rgb(hex);
  const r = linearizeChannel(red / 255);
  const g = linearizeChannel(green / 255);
  const b = linearizeChannel(blue / 255);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const linearizeChannel = (value: number) => (value <= 0.03928 ? value / 12.92 : ((value + 0.055) / 1.055) ** 2.4);
