import { type FC, memo } from 'react';

import type { RenderDescription, SceneVideo } from '@cofenster/render-description';
import { SceneVideoElement } from './SceneVideoElement';

export const SceneVideoSection: FC<{
  sceneVideo: SceneVideo;
  background: RenderDescription['background'];
  startFrom?: number;
  endAt?: number;
}> = memo(function SceneVideoSection({ sceneVideo, background, startFrom = 0, endAt }) {
  return (
    <SceneVideoElement
      src={sceneVideo.srcUrl}
      muted={sceneVideo.volume === 0}
      background={background}
      startFrom={startFrom}
      endAt={endAt}
      transform={sceneVideo.transform}
      width={sceneVideo.width}
      height={sceneVideo.height}
    />
  );
});
