import { Global } from '@emotion/react';
import type { FC } from 'react';

import { useCofensterVideo } from '../../../context/cofensterVideo';
import { createColorVariables } from '../../../helpers/createColorVariables';

export const Colors: FC = () => {
  const { renderDescription } = useCofensterVideo();
  const { colors } = renderDescription;

  if (!colors) return null;

  return (
    <Global
      styles={{
        '.cofenster-video': {
          ...createColorVariables(colors.primary, 'primary-color'),
          ...createColorVariables(colors.secondary, 'secondary-color'),
        },
      }}
    />
  );
};
