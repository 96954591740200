import type { FC } from 'react';

import {
  Card,
  GridContainer,
  GridItem,
  Headline,
  Spacing,
  formatDateTime,
  formatDuration,
  styled,
} from '@cofenster/web-components';

import {
  Definition,
  DefinitionContent,
  DefinitionList,
  DefinitionTerm,
} from '../../../../components/display/DefinitionList';
import { type ExtendedRenderJob, getStatusProps } from '../../../../contexts/exports';
import { RestartInfo } from '../RestartInfo';
import { StatusIcon } from '../StatusIcon';

const InlineContainer = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(0.5),
}));

const ErrorContent = styled('pre')(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.brand.grey50,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  maxHeight: 250,
  overflow: 'auto',
  fontSize: '80%',
  '&:focus-visible': theme.mixins.focusRing,
}));

const Separator = styled('hr')(({ theme }) => ({
  height: 1,
  width: '100%',
  backgroundColor: theme.palette.brand.grey300,
  border: 0,
  margin: theme.spacing(2, 0),
}));

export const Metadata: FC<ExtendedRenderJob> = (extendedRenderJob) => {
  const { job, project, account, subtitle, restartMode } = extendedRenderJob;
  const renderDescription = job.renderDescription;
  const introOutro = ['intro', 'outro']
    .map((type) => (renderDescription?.[type as 'intro' | 'outro'] ? type : undefined))
    .filter(Boolean)
    .join(' & ');

  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <Card>
          <Spacing bottom={2}>
            <Headline variant="h4" component="h2">
              Render job
            </Headline>
          </Spacing>

          <DefinitionList>
            <Definition>
              <DefinitionTerm>ID</DefinitionTerm>
              <DefinitionContent>{job.id}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Created at</DefinitionTerm>
              <DefinitionContent>{formatDateTime(job.createdAt)}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Updated at</DefinitionTerm>
              <DefinitionContent>{job.updatedAt ? formatDateTime(job.updatedAt) : 'n/a'}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Type</DefinitionTerm>
              <DefinitionContent>
                {subtitle ? 'Subtitle' : job.subtitlesLanguage ? 'Subtitles (new)' : 'Project'}
              </DefinitionContent>
            </Definition>

            {job.subtitlesLanguage && (
              <Definition data-half>
                <DefinitionTerm>Language</DefinitionTerm>
                <DefinitionContent>{job.subtitlesLanguage}</DefinitionContent>
              </Definition>
            )}

            <Definition data-half>
              <DefinitionTerm>Status</DefinitionTerm>
              <DefinitionContent>
                <InlineContainer>
                  <StatusIcon size="xs" backgroundSize="m" status={job.status} />
                  {getStatusProps(job.status).label}
                </InlineContainer>
              </DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Remotion</DefinitionTerm>
              <DefinitionContent>{job.useNg ? 'NextGen' : 'Current'}</DefinitionContent>
            </Definition>

            {job.error && (
              <Definition>
                <DefinitionTerm>Error</DefinitionTerm>
                <DefinitionContent>
                  <ErrorContent role="region" tabIndex={0} aria-label="Error">
                    {job.error}
                  </ErrorContent>
                </DefinitionContent>
              </Definition>
            )}

            <Definition data-half>
              <DefinitionTerm>Step</DefinitionTerm>
              <DefinitionContent>{job.step ?? 'n/a'}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Step progress</DefinitionTerm>
              <DefinitionContent>
                {job.status !== 'Failed' && job.stepProgress ? `${(job.stepProgress * 100).toFixed(2)}%` : 'n/a'}
              </DefinitionContent>
            </Definition>
          </DefinitionList>

          {!restartMode && (
            <>
              <Separator />
              <RestartInfo {...extendedRenderJob} />
            </>
          )}
        </Card>
      </GridItem>

      <GridItem xs={12} md={6}>
        <Card>
          <Spacing bottom={2}>
            <Headline variant="h4" component="h2">
              Metadata
            </Headline>
          </Spacing>

          <DefinitionList>
            <Definition>
              <DefinitionTerm>Project name</DefinitionTerm>
              <DefinitionContent>{project?.name ?? 'Deleted project'}</DefinitionContent>
            </Definition>

            <Definition>
              <DefinitionTerm>Project ID</DefinitionTerm>
              <DefinitionContent>{project?.id ?? 'n/a'}</DefinitionContent>
            </Definition>

            {project?.deletedAt && (
              <Definition>
                <DefinitionTerm>Project status</DefinitionTerm>
                <DefinitionContent>
                  This project was deleted on {formatDateTime(project.deletedAt)}, but still exists in the database.
                </DefinitionContent>
              </Definition>
            )}

            <Definition>
              <DefinitionTerm>Template</DefinitionTerm>
              <DefinitionContent>{renderDescription?.template}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Duration</DefinitionTerm>
              <DefinitionContent>{formatDuration(renderDescription?.totalDurationInSeconds ?? 0)}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm># of scenes</DefinitionTerm>
              <DefinitionContent>
                {renderDescription?.scenes.length}
                {introOutro ? ` + ${introOutro}` : null}
              </DefinitionContent>
            </Definition>

            <Separator />

            <Definition>
              <DefinitionTerm>Account name</DefinitionTerm>
              <DefinitionContent>{account?.name ?? 'Deleted account'}</DefinitionContent>
            </Definition>

            <Definition>
              <DefinitionTerm>Account ID</DefinitionTerm>
              <DefinitionContent>{account?.id ?? 'n/a'}</DefinitionContent>
            </Definition>

            {account?.deletedAt && (
              <Definition>
                <DefinitionTerm>Account status</DefinitionTerm>
                <DefinitionContent>
                  This account was deleted on {formatDateTime(account.deletedAt)}, but still exists in the database.
                </DefinitionContent>
              </Definition>
            )}
          </DefinitionList>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
