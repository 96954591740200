import type { PlayerRef } from '@remotion/player';
import { type RefObject, useEffect, useState } from 'react';

export const useBuffering = (playerRef: RefObject<PlayerRef>) => {
  const [isBuffering, setIsBuffering] = useState(false);

  useEffect(() => {
    const { current } = playerRef;

    if (!current) return;

    const onBuffering = () => setIsBuffering(true);
    const onResume = () => setIsBuffering(false);

    current.addEventListener('waiting', onBuffering);
    current.addEventListener('resume', onResume);

    return () => {
      current.removeEventListener('waiting', onBuffering);
      current.removeEventListener('resume', onResume);
    };
  }, [playerRef]);

  return isBuffering;
};
