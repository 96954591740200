import type { FC } from 'react';
import { Audio } from 'remotion';

import type { SceneAudio as SceneAudioDescription } from '@cofenster/render-description';

import { Sequence } from '../../../../../remotion/Sequence';

export type SceneAudioProps = {
  sceneAudio: SceneAudioDescription;
};

export const SceneAudio: FC<SceneAudioProps> = ({ sceneAudio }) => {
  if (sceneAudio.volume === 0 || sceneAudio.status !== 'Ready' || !sceneAudio.srcUrl) {
    return null;
  }
  return (
    <Sequence name={`SceneAudio-${sceneAudio.id}`} from={0}>
      <Audio src={sceneAudio.srcUrl} volume={sceneAudio.volume ?? 1} pauseWhenBuffering />
    </Sequence>
  );
};
