import type { SubtitleNode as RenderDescriptionSubtitleNode, SceneVideoSection } from '../types/RenderDescription';

import { assetWithSectionsTiming } from './assetWithSectionsTiming';

type VideoSection = Omit<SceneVideoSection, '__typename'>;
type SubtitleNode = Omit<RenderDescriptionSubtitleNode, '__typename'>;

export const subtitleNodesBySections = (
  subtitleNodes: SubtitleNode[],
  videoSections: VideoSection[],
  duration: number
) => {
  if (!videoSections.length) return subtitleNodes.filter(isNotEmpty);

  const timing = assetWithSectionsTiming(duration, videoSections);

  return Array.from(videoSections)
    .sort((a, b) => a.offsetIn - b.offsetIn)
    .flatMap((videoSection) => {
      return subtitleNodes
        .filter((subtitleNode) => isSubtitleNodeOverlappingVideoSection(videoSection, subtitleNode))
        .filter(isNotEmpty)
        .map(({ start, end, ...rest }) => ({
          start: timing.fromAsset(Math.max(start, videoSection.offsetIn)),
          end: timing.fromAsset(Math.min(end, videoSection.offsetOut)),
          ...rest,
        }));
    });
};

const isNotEmpty = (subtitleNode: SubtitleNode) => subtitleNode.text.trim() !== '';

export const isSubtitleNodeOverlappingVideoSection = (videoSection: VideoSection, subtitleNode: SubtitleNode) =>
  subtitleNode.start < videoSection.offsetOut && subtitleNode.end > videoSection.offsetIn;

export const isSubtitleNodeWithinVideoSection = (videoSection: VideoSection, subtitleNode: SubtitleNode) =>
  subtitleNode.start >= videoSection.offsetIn && subtitleNode.end <= videoSection.offsetOut;
